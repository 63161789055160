import Vue from "vue";
import {
    concludeOrder,
    downloadContract,
    showContractFile,
    downloadInvoiceLegal,
    downloadOrder,
    downloadPdf,
    getPaymentInvoice
} from "@/api/order";
import {downloadBusContract} from "@/api/downloadBusContract";
import {downloadGreenCardContract} from "@/api/downloadGreenCardContract";
import {downloadTourismContract} from "@/api/downloadTourismContract";
import {contractGreenCard} from "@/api/contractGreenCard";
import {calculate} from "@/api/offer";
import {list as calculateGreenCard} from "@/api/greenCardInsuranceCompany";
import {list as calculateTourism} from "@/api/tourismInsuranceCompany";
import FileDownload from "js-file-download";

export default {
    data: function () {
        return {
            periodOptions: [
                {"id": 15, "count":"15", "key":"d", "name" : "15 д" },
                {"id": 1, "count":"1", "key":"m", "name" : "1 міс" },
                {"id": 2, "count":"2", "key":"m", "name" : "2 міс" },
                {"id": 3, "count":"3", "key":"m", "name" : "3 міс" },
                {"id": 4, "count":"4", "key":"m", "name" : "4 міс" },
                {"id": 5, "count":"5", "key":"m", "name" : "5 міс" },
                {"id": 6, "count":"6", "key":"m", "name" : "6 міс" },
                {"id": 7, "count":"7", "key":"m", "name" : "7 міс" },
                {"id": 8, "count":"8", "key":"m", "name" : "8 міс" },
                {"id": 9, "count":"9", "key":"m", "name" : "9 міс" },
                {"id": 10, "count":"10", "key":"m", "name" : "10 міс" },
                {"id": 11, "count":"11", "key":"m", "name" : "11 міс" },
                {"id": 12, "count":"12", "key":"m", "name" : "12 міс" },
            ],
            carTypes: [
                {"name":"Легкові автомобілі","group":"CAR","external_id":"B1",
                    "types":[{"name":"до 1600 см3","value":"B1"},{"name":"1601-2000 см3","value":"B2"},{"name":"2001-3000 см3","value":"B3"},
                        {"name":"более 3001 см3","value":"B4"}],"engines":[]},
                {"name":"Автобус","group":"BUS","engines":[],"types":[{"name":"менше 20 осіб","value":"D1"},
                        {"name":"більше 20 осіб","value":"D2"}]},{"name":"Вантажні автомобілі","group":"CARGO","engines":[],
                    "types":[{"name":"менше 2-х тонн","value":"C1"},{"name":"більше 2-х тонн","value":"C2"}]},
                {"name":"Причіп, Трейлер","group":"TRAILER","engine":null,"engines":[],"types":[{"name":"До вантажного авто","value":"E"},{"name":"До легкового авто","value":"F"}]},{"name":"Мотоцикли та моторолери"
                    ,"group":"BIKE","external_id":"A1","types":[{"name":"300 см3 або менше","value":"A1"},{"name":"більше 300 см3","value":"A2"}],"engines":[]},
                {"name":"Легковий електромобіль","group":"CAR","external_id":"B5","engine":null,"engines":[],"types":[]}
            ],
            carTypesGreenCard: [
                {"name":"Легкові автомобілі","group":"CAR", "external_id":"A",
                    "engines":[
                        {"name":"до 1600 см3","value":"A1"},
                        {"name":"1601-2000 см3","value":"A2"},
                        {"name":"2001-3000 см3","value":"A3"},
                        {"name":"более 3001 см3","value":"A4"}],
                    "types":[]},
                {"name":"Автобус","group":"BUS", "external_id":"E", "engines":[],
                    "types":[
                        {"name":"менше 20 осіб","value":"E1"},
                        {"name":"більше 20 осіб","value":"E2"}]},
                {"name":"Вантажні автомобілі","group":"CARGO", "external_id":"C", "engines":[],
                    "types":[
                        {"name":"менше 2-х тонн","value":"C1"},
                        {"name":"більше 2-х тонн","value":"C2"}]},
                {"name":"Причіп, Трейлер","group":"TRAILER","engine":null, "external_id":"F", "engines":[],
                    "types":[{"name":"До вантажного авто","value":"F"},
                        {"name":"До легкового авто","value":"F"}]},
                {"name":"Мотоцикли та моторолери","group":"BIKE", "external_id":"B",
                    "engines":[{"name":"300 см3 або менше","value":"B1"},
                        {"name":"більше 300 см3","value":"B2"}], "types":[]}
            ],
            citiesOptions: [ {"id": 1,"name": "Київ","name_full_name_ua": "м. Київ, Україна", "zone":1},
                {"id": 9,"name": "Дніпро","name_full_name_ua": "м. Дніпро, Дніпропетровська  обл., Україна", "zone":2},
                {"id": 11,"name": "Львів","name_full_name_ua": "м. Львів, Львівська  обл., Україна", "zone":1},
                {"id": 13,"name": "Одеса","name_full_name_ua": "м. Одеса, Одеська  обл., Україна", "zone":2},
                {"id": 14,"name": "Харків","name_full_name_ua": "м. Харків, Харківська  обл., Україна", "zone":2},
                {"id": 16,"name": "Запоріжжя","name_full_name_ua": "м. Запоріжжя, Запорізька  обл., Україна", "zone":3},
                {"id": 17,"name": "Кривий Ріг","name_full_name_ua": "м. Кривий Ріг, Дніпропетровська  обл., Україна", "zone":3},
                {"id": 21,"name": "Вінниця","name_full_name_ua": "м. Вінниця, Вінницька  обл., Україна", "zone":4},
                {"id": 36,"name": "Маріуполь","name_full_name_ua": "м. Маріуполь, Донецька  обл., Україна", "zone":4},
                {"id": 41,"name": "Полтава","name_full_name_ua": "м. Полтава, Полтавська  обл., Україна", "zone":4}],
            experience: {"name":"Менше 3 років","value":false},
        };
    },
    computed: {
        allOrderTypes: function () {
            return this.$store.getters.ALL_ORDER_TYPES
        }
    },
    methods: {
        declare: function(order, isCopy = false, isProlong = false, step = 1, isNewProlong = false) {
            let period_id = order.offer.period_id ?? this.getOrderContractPeriod(order).id;
            let carType = this.getCarType(order);
            let startDate = (isCopy)? this.getStartDate(order, isProlong) : order?.offer?.startDate;
            let otkPeriod = period_id ? period_id : this.getOrderContractPeriod(order).id;
            let otkDate = (isCopy && order.offer.otkDate) ?
                this.getOrderOtkDate(startDate, otkPeriod, isProlong) : // this.getOrderEndDate(startDate, period_id) : //this.getOrderOtkDate(startDate, otkPeriod, isProlong) :
                (order.offer.otkDate ? moment(order.offer.otkDate, "DD.MM.YYYY").unix() : null);
            let endDate = (isCopy &&  order.offer.endDate) ? this.getOrderEndDate(startDate, period_id) : (order.offer.endDate ? moment(order.offer.endDate, "DD.MM.YYYY").unix() : null);
            const offerDriverBirthDate = order.offer.calcOptions?.car?.driverBirthdayAt ? moment(order.offer.calcOptions?.car?.driverBirthdayAt, "YYYY-MM-DD").format('DD.MM.YYYY') : null;
            const birthDate = new Date(new Date().setFullYear(new Date().getFullYear() - 35)).toLocaleDateString('uk-UA');

            let discountData = this.calcDiscountData(order);

            if(parseInt(order.typeId) === 5) {
                let offerInfo = {
                    "id":order.offer.external_id,
                    "company":order.companyInfo.info,
                    "tripProgram":order.offer.trip_program,
                    "title":order.companyInfo.name,
                    "options":order.offer.options,
                    "programs":order.offer.programs,
                    "members":order.offer.members,
                    "ages":order.offer.ages,
                    "external_id":order.offer.external_id,
                    "startDate":order.offer.startDate,
                    "endDate":order.offer.endDate,
                    "moduleId":order.moduleId,
                    "period_option":order.offer.period_option,
                    "risks":order.offer.risks,
                    "price":order.offer.price,
                    "limit":order.offer.coverage,
                    "limit_currency":order.offer.limit_currency,
                    "offerId":order.offer.id,
                    "earnings": order.earnings,
                    "orderId": (isCopy) ? null : order.id,
                    "purposeOfTrip":order.offer.purposeOfTrip,
                    "payments":order.payments,
                    "fullPrice":order.allPrice,
                    "yearProgram":order.offer.yearProgram,
                    "available_documents": order.offer?.available_documents ?? null,
                    "allowed_documents": order.offer?.allowed_documents ?? null,
                    "discountPrice" : !discountData ? this.showDiscount(order) : 0,
                    "dynamicDiscountPrice" : discountData ? this.showDiscount(order) : 0,
                };

                if(discountData){
                    offerInfo = { ...offerInfo, ...discountData };
                }

                offerInfo.company.id = order.companyInfo.companyId;

                this.humans = [
                    {name: 'Діти до 1 року ', value: 0, min: 0, max: 1, birthDates: [], id:1},
                    {name: 'Діти до 3 років ', value: 0, min: 2, max: 3,  birthDates: [], id:2},
                    {name: 'Підлітки до 18 років ', value: 0, min: 4, max: 18,  birthDates: [], id:3},
                    {name: 'Дорослі до 69 років ', value: 0, min: 19, max: 69, birthDates: [], id:4},
                    {name: 'Дорослі 70-75 років ', value: 0, min: 70, max: 75, birthDates: [], id:5},
                    {name: 'Літні 76-100 років ', value: 0, min: 76, max: 100, birthDates: [], id:6}
                ];

                this.birthDates = [];
                this.fillHumans(order.insurants);

                let searchInfo = {
                    end: order.contract.finishedAt,
                    date: order.contract.startedAt,
                    days: order.offer.period_option,
                    country: {id:order.offer.country.id,ewa_id:order.offer.country.ewa_id,name:order.offer.country.name},
                    tourists: order.offer.tourists,
                    humans: this.humans,
                    yearProgram: order.offer.yearProgram,
                    birthDates: this.birthDates,
                    filters: {
                        "franchise": "",
                        "options_list": [],
                        "activities": {
                            "name": "Без активності",
                            "value": "no_activities"
                        },
                        "activitiesItem": "Без активностей"
                    },
                    activities: "no_activities",
                };

                let userInfo = {
                    "identificationCode":order.insurant.documentation.uin,
                    "withoutIdentificationCode":order.insurant.documentation.withoutUin,
                    "name":order.insurant.name,
                    "surname":order.insurant.surname,
                    "dateBirth":order.insurant.dateBirthAt,
                    "phone":order.insurant.phone,
                    "mail":order.insurant.email,
                    "city":order.insurant.cityObject,
                    "street":order.insurant.street,
                    "house":order.insurant.house,
                    "apartment":order.insurant.apartment,

                    "documentation": {
                        //TODO текущее значение нобходимо будет брать с словаря(api)
                        documentType: order.insurant.documentation.documentType,
                        type: order.insurant.documentation.type,
                        serial: order.insurant.documentation.serial,
                        number: order.insurant.documentation.number,
                        issuedBy: order.insurant.documentation.issuedBy,
                        dateOfIssue: order.insurant.documentation.dateOfIssue,
                        endDateOfIssue: order.insurant.documentation.endDateOfIssue,
                        // endDateOfIssue: order.insurant.documentation.dateOfIssue,

                    },
                    "documentType":order.insurant.documentation.documentType,
                    "passportSerial":order.insurant.documentation.serial,
                    "passportNumber":order.insurant.documentation.number,
                    "passportDate":order.insurant.documentation.dateOfIssue,
                };

                let TouristsListInfo = [];

                order.insurants.forEach((item, key) => {
                    TouristsListInfo.push({
                        "name":item.name,
                        "surname":item.surname,
                        "dateBirth":item.dateBirthAt,
                        "identificationCode":item.documentation.uin,
                        "withoutIdentificationCode":item.documentation.withoutUin,
                        "passportSerial":item.documentation.serial,
                        "passportNumber":item.documentation.number,
                        "passportDate":item.documentation.dateOfIssue,
                        "passportIssuedBy":item.documentation.issuedBy,
                        "passportEndDate":item.documentation.endDateOfIssue,
                        "documentType": item.documentation.type,
                    });
                });

                let params = {
                    type: "next",
                    statusId: 0
                };
                this.$store.commit('CLEAR_TOURISM_INFO_TOURISTSLIST');
                this.$store.commit('CLEAR_SEARCH_TOURISM_PARAMS');
                this.$store.commit('CLEAR_TOURISM_INFO_OFFER');
                this.$store.commit('CLEAR_TOURISM_INFO_USER');
                this.$store.commit('CLEAR_TOURISM_FULL_ORDER');
                this.$store.commit('SET_TOURISM_INFO_OFFER', offerInfo);
                this.$store.commit('SET_TOURISM_INFO_USER', userInfo);
                this.$store.commit('SET_TOURISM_INFO_TOURISTSLIST', TouristsListInfo);
                this.$store.commit('SET_SEARCH_TOURISM_PARAMS', searchInfo);
                this.orderId  = (isCopy) ? null : order.id;
                this.$store.commit('SET_TOURISM_FULL_ORDER', this.orderId);

                if(isNewProlong){
                    Vue.set(this.loadMoreOptions, order.id + parseInt(order.typeId), false);
                    this.loadProcessNew = false;
                    this.$router.push({name: `tourismResult`, query: {from: 'prolongation'}});
                }else{
                    if (order.otpHistory && order.otpHistory.date_offer_agreed) {
                        this.$router.push({name: 'tourismFinnaly', query: {from: 'history'}});
                    } else {
                        this.$router.push({name: 'tourismSteps', query: {from: 'prolongation'}});
                    }
                }
            }
            else if(parseInt(order.typeId) === 3) {
                let finishedAt = null;
                let userTypeList = this.$store.getters.CUSTOMER_TYPES;
                let autoTyprsList = this.$store.getters.AUTO_TYPES_LIST;
                order.offer.customerType = order.insurant.customerTypeId;
                let searchInfo = {};
                if (isCopy) {
                        searchInfo = {
                        carType: carType,
                        country: order.offer.country,
                        periodOption: period_id ? this.periodOptions.find((item) => item.id === period_id) : this.getOrderContractPeriod(order),
                        startDate: startDate,
                        userType: userTypeList.find(option => option.id === order.offer.userType),
                        dateFromMax: order.offer?.dateFromMax,
                    }
                }else{
                        searchInfo = {
                        carType: autoTyprsList.find((item) => item.external_id == order.insuranceObject.autoCategory),
                        userType: userTypeList.find(option => option.id === order.offer.userType),
                        country: {id: order.offer.country.id,
                            name: order.offer.country.name,
                            name_ru: order.offer.country.name},
                        startDate: startDate, //order.offer.startDate,
                        periodOption: order.offer.period,
                    }
                }

                let infoUser = {
                    documentType: order.insurant.documentation.documentType,
                    identificationCode: order.insurant.documentation.uin,
                    erdpyCode: order.insurant.documentation.uin,
                    name: order.insurant.name,
                    name_ua: order.insurant.name_ua,
                    surname: order.insurant.surname,
                    surname_ua: order.insurant.surname_ua,
                    patronymic_ua: order.insurant.patronymic_ua,
                    dateBirth: order.insurant.dateBirthAt,
                    phone: order.insurant.phone,
                    mail: order.insurant.email,
                    city: order.insurant.city,
                    street: order.insurant.street,
                    house: order.insurant.house,
                    apartment: order.insurant.apartment,
                    // new
                    documentation: {
                        //TODO текущее значение нобходимо будет брать с словаря(api)
                        type: order.insurant.documentation.documentType,
                        serial: order.insurant.documentation.serial,
                        number: order.insurant.documentation.number,
                        issuedBy: order.insurant.documentation.issuedBy,
                        dateOfIssue: order.insurant.documentation.dateOfIssue,
                        endDateOfIssue: order.insurant.documentation.endDateOfIssue,
                    },
                    internationalPassport: (order.insurant.documentation.documentType == 2) ? order.insurant.documentation.number : null,
                    passportSerial: order.insurant.documentation.serial,
                    passportNumber: order.insurant.documentation.number, //((order.insurant.documentation.documentType == 4)) ?
                    passportCompany: order.insurant.documentation.issuedBy,
                    passportDate: order.insurant.documentation.dateOfIssue,

                    withoutIdentificationCode: order.insurant.documentation.withoutUin,
                    withoutDocuments:order.insurant.documentation.withoutDocuments
                };
                if (order.insurant.contact) {
                    infoUser.contact = order.insurant.contact
                }

                let carInfo = {
                    vin: order.insuranceObject.vin,
                    year: order.insuranceObject.year,
                    brand: order.insuranceObject.brand,
                    model: order.insuranceObject.model,
                    number: order.insuranceObject.number,
                    city: {
                        id:order.insuranceObject.registrationPlaceId,
                        name:order.insuranceObject.registrationPlace,
                        name_full_name_ua:order.insuranceObject.registrationPlace
                    },
                    withoutVin: order.insuranceObject.withoutVin,
                    birthdayAt: offerDriverBirthDate ?? (order?.insurant?.dateBirthAt || birthDate),
                    //TODO need rename internalAutoCategory
                    autoCategory: order.insuranceObject.internalAutoCategory,
                    additionalParameters: order.insuranceObject.additionalParameters
                };

                if (isProlong || isNewProlong) {
                    order.offer.prolongOrderId = order.id;

                    if (order.contract?.finishedAt) {
                        finishedAt = moment(order.contract?.finishedAt, "DD.MM.YYYY").add(1, 'days').format("DD-MM-YYYY")
                    }

                    order.offer.startDate = finishedAt;
                }

                order.offer.company = order.companyInfo.info;
                order.offer.autoCategoryType = order.insuranceObject.autoCategory;
                order.offer.company.id = order.offer.company.company_id;
                order.offer.company.logo = process.env.VUE_APP_ADMIN_DOMAIN+order.offer.company.logo;
                order.offer.companyNamePublic = order.companyInfo.publicName;
                order.offer.offerId = order.offer.id;
                order.offer.price = order.price;
                order.offer.payments = order.payments;
                order.offer.earnings = order.earnings;
                order.offer.orderId = (isCopy) ? null : order.id;
                order.offer.dateFromMax = order.offer?.dateFromMax || 90;
                order.offer.startDate = startDate;
                order.offer.endDate = moment.unix(endDate).format('DD.MM.YYYY');
                order.offer.discountPrice = !discountData ? this.showDiscount(order) : 0;
                order.offer.dynamicDiscountPrice = discountData ? this.showDiscount(order) : 0;

                if(discountData){
                    order.offer = { ...order.offer, ...discountData };
                }

                if(step == 2){
                    order.offer.confirmContractStatus = true;
                }

                this.$store.commit('CLEAR_GREENCARD_FULL_ORDER');
                this.$store.commit('SET_GREENCARD_INFO_OFFER', order.offer);
                this.$store.commit('SET_GREENCARD_SEARCH_PARAMS', searchInfo);
                this.$store.commit('SET_GREENCARD_INFO_USER', infoUser);
                this.$store.commit('SET_GREENCARD_INFO_CAR', carInfo);
                this.$store.commit('SET_GREENCARD_FULL_ORDER', (isCopy) ? null : order.id);
                this.$store.commit('SET_GREENCARD_CONTRACT_ID', order.contractId);

                if(isNewProlong){
                    Vue.set(this.loadMoreOptions, order.id + parseInt(order.typeId), false);
                    this.loadProcessNew = false;
                    this.$router.push({name: `greenCardResults`, query: {from: 'history'}});
                }else{
                    if(step == 1) {
                        this.$router.push({name: 'greenCardSteps', query: {from: 'history'}});
                    }
                    else if(step == 2){
                        this.$router.push({name: 'greenCardFinnaly', query: {from: 'history'}});
                    }
                }
            }
            else if(parseInt(order.typeId) == 1) {
                let finishedAt = null;
                Vue.set(this.loadMoreOptions, order.id + parseInt(order.typeId), true);

                const carType = this.getCarType(order);

                let params = {
                    fromProlongation: isNewProlong ? true : false,
                    isTaxi: order.insuranceObject.isTaxi ? 1 : 0,
                    isEuroCar: order.insuranceObject.isEuroCar ? 1 : 0,
                    experience: this.experience,
                    franchise: 'all',
                    autoCategoryType: order.insuranceObject.autoCategory,
                    registrationPlaceId: order.offer.cityId,
                    zone: order.insuranceObject?.registrationPlaceZone,
                    period_id: period_id,
                    cityName: order.insuranceObject.registrationPlace,
                    otkDate: otkDate,
                    endDate: endDate,
                    withoutOtk: order.withoutOtk ? 1 : 0,
                    startDate: startDate,
                    dateFromMax: order?.offer?.dateFromMax,
                    carTypeInfo: carType,
                    cityInfo: order.offer?.city,
                    customerPrivilege: order.insurant.privilegeTypeId ?? null,
                    registrationType: order.registrationType,
                    customerType: order.insurant?.customerTypeId,
                    car: {
                        vin: order.insuranceObject.vin,
                        year: order.insuranceObject.year,
                        brand: order.insuranceObject.brand,
                        model: order.insuranceObject.model,
                        number: order.insuranceObject.number,
                        withoutVin: order.insuranceObject.withoutVin,
                        birthdayAt: offerDriverBirthDate ?? (order?.insurant?.dateBirthAt || birthDate),
                        additionalParameters: order.insuranceObject.additionalParameters,
                        kilometers: order.insuranceObject.kilometers,
                        odometr: order.insuranceObject.odometr,
                    }
                };
                let offer = {};
                let dgoSelected = {};

                if(order?.dgo?.id && order?.dgo?.compensation){
                    const matchingCoverage = this.findMatchingCoverage(order.dgo, order.listDgo);
                    if(matchingCoverage){
                        dgoSelected =  matchingCoverage;
                        dgoSelected.price =  matchingCoverage.cost;
                        offer.dgoId = matchingCoverage.id;
                        offer.dgoCoverage = matchingCoverage.coverage;
                        offer.dgoCompanyId = order?.dgo.company_id;
                        offer.dgoPrice = order.dgoPrice = matchingCoverage.cost;
                    }
                }

                let requestData = {
                    registrationType: order.registrationType,
                    //price: this.showPrice(order, true),
                    //dgoPrice: dgoSelected?.price ?? null,
                    //autolawyerPrice: this.showAutolawyerPrice(order),
                    //franchise: order.offer?.franchise,
                    autoCategoryType: order.insuranceObject.autoCategory,
                    registrationPlaceId: order.offer.cityId,
                    period_id: period_id,
                    startDate: startDate,
                    zone: order.insuranceObject?.registrationPlaceZone,
                    customerPrivilege: order.insurant.privilegeTypeId,
                    customerType: order.insurant.customerTypeId,
                    withoutOtk: order.withoutOtk ? 1 : 0,
                    otkDate: otkDate,
                    endDate: endDate,
                    isTaxi: order.insuranceObject.isTaxi,
                    isEuroCar: order.insuranceObject.isEuroCar,
                    cityInfo: {
                        //TODO это бардак, нужно зарефакторить текущие данные по догоову. ЧТо бы были одинаковые ключи
                        name_full_name_ua: order.insurant.cityObject?.name_full_name_ua || "",
                        name: order.insurant.cityObject?.name_ua || "",
                    },
                    cityName: order.insuranceObject.registrationPlace || "",
                    car: {
                        vin: order.insuranceObject.vin,
                        year: order.insuranceObject.year,
                        brand: order.insuranceObject.brand,
                        model: order.insuranceObject.model,
                        number: order.insuranceObject.number,
                        withoutVin: order.insuranceObject.withoutVin,
                        birthdayAt: offerDriverBirthDate ?? (order?.insurant?.dateBirthAt || birthDate),
                        additionalParameters: order.insuranceObject.additionalParameters,
                        kilometers: order.insuranceObject.kilometers,
                        odometr: order.insuranceObject.odometr,
                    }
                }

                //альтернативный парсинг адреса для ukasko.ua и лендингов
                let fullAddress = order.insurant.address ? order.insurant.address.split(',') : '';
                const cityNormal = this.getCity(order.insurant.city);
                const streetAlt = fullAddress[0] ? fullAddress[0].trim() : null;
                const houseAlt = fullAddress[1] ? fullAddress[1].trim() : null;
                const apartmentAlt = fullAddress[2] ? fullAddress[2].trim() : null;

                let cityRegistrationAlternative = cityNormal ? this.citiesOptions.find(item => item.name === cityNormal) : null;



                offer = { ...offer,
                    programmName: order.offer?.programmName,
                    available_documents: order.offer?.available_documents ?? null,
                    allowed_documents: order.offer?.allowed_documents ?? null,
                    autoCategoryType : order.insuranceObject.autoCategory,
                    franchise: order.offer?.franchise,
                    moduleId: order.moduleId,
                    companyName: order.companyInfo.publicName,
                    listDgo: order.listDgo,
                    earnings: order.earnings,
                    listAutolawyer: order?.listAutolawyer,
                    autolawyerId: order?.autolawyer?.id, // ?? null,
                    autolawyerZone: order?.autolawyer?.zone, // ?? null,
                    autolawyerAutoCategoryType: order?.autolawyer?.auto_category_type, // ?? null,
                    autolawyerPrice: this.showAutolawyerPrice(order),
                    autolawyerProgram: order?.autolawyer?.program, // ?? null,
                    price: this.showPrice(order, true),
                    discountPrice: !discountData ? this.showDiscount(order) : 0,
                    dynamicDiscountPrice: discountData ? this.showDiscount(order) : 0,
                    cashbackPrice: this.showCashBack(order),

                    offerId: order.offer.id,
                    cityRegistration: order.offer.city,
                    companyId: order.companyInfo.companyId,
                    link_id: order.offer.link_id ?? order.link_id ?? null,
                    ex_id:order.offer.ex_id,

                    company: {
                        inn_status: order?.company?.innStatus
                    },
                    userData: {
                        name: order.insurant.name,
                        phone: order.insurant.phone,
                        email: order.insurant.email,
                        surname: order.insurant.surname,
                        dateBirth: order.insurant.dateBirthAt,
                        patronymic: order.insurant.patronymic,
                        identificationCode: order.insurant.documentation.uin,
                        withoutIdentificationCode: order.insurant.documentation.withoutUin,
                        withoutDocuments: order.insurant.documentation.withoutDocuments,
                        documentType: order.insurant.documentation.type,
                        documentation: {
                            //TODO текущее значение нобходимо будет брать с словаря(api)
                            type: order.insurant.documentation.documentType,
                            serial: order.insurant.documentation.serial,
                            number: order.insurant.documentation.number,
                            issuedBy: order.insurant.documentation.issuedBy,
                            dateOfIssue: order.insurant.documentation.dateOfIssue,
                            endDateOfIssue: order.insurant.documentation.endDateOfIssue,
                        },
                        privilege: order.insurant.privilege,
                        address: {
                            street: order.insurant.street ?? streetAlt,
                            house: order.insurant.house ?? houseAlt,
                            number: order.insurant.apartment ?? apartmentAlt,
                            cityId: order.insurant.cityId,
                            cityObject: order.insurant.cityObject,
                            cityName: order.insurant.city,
                        },
                    },
                    usageMonths: order?.offer?.usageMonths,
                    dateFromMax:  order.offer?.dateFromMax || 90
                };

                if(discountData){
                    offer = { ...offer, ...discountData };
                }

                if (this.isLegalCustomerType(order.insurant.customerTypeId)) {
                    offer.userData.erdpyCode = order.insurant.documentation.uin;
                } else {
                    offer.userData.identificationCode = order.insurant.documentation.uin;
                }

                if (isProlong || isNewProlong) {
                    offer.prolongOrderId = order.id;

                    if (order.contract?.finishedAt) {
                        finishedAt = moment(order.contract?.finishedAt, "DD.MM.YYYY").add(1, 'days').format("DD.MM.YYYY")
                    }

                    offer.startDate = finishedAt;
                }

                let fullInfoOrder = {
                    autoCategoryType : order.insuranceObject.autoCategory,
                    cityRegistration: order.insurant.cityObject ?? cityRegistrationAlternative ?? null,
                    customer: offer.userData,
                    dgoPrice: dgoSelected?.cost ?? null,
                    autolawyerPrice: order?.autolawyer?.price ?? null,
                    payments: order.payments,
                    earnings: order.earnings,
                    price: this.showPrice(order, false),
                    discountPrice: !discountData ? this.showDiscount(order) : 0,
                    dynamicDiscountPrice: discountData ? this.showDiscount(order) : 0,
                    franchise: order.offer?.franchise,
                    dgo:dgoSelected,
                    orderId: (isCopy) ? null : order.id,
                    parentOrderId: (isCopy) ? order.id : null,
                    car: {
                        vin: order.insuranceObject.vin,
                        year: order.insuranceObject.year,
                        brand: order.insuranceObject.brand,
                        model: order.insuranceObject.model,
                        number: order.insuranceObject.number,
                        birthdayAt: offerDriverBirthDate ?? (order?.insurant?.dateBirthAt || birthDate),
                    }
                };


                if(discountData){
                    fullInfoOrder = { ...fullInfoOrder, ...discountData };
                }
                if(step == 2){
                    fullInfoOrder.confirmContractStatus = true;
                }

                let period = this.getOrderContractPeriod(order);

                let dateInfo = {
                    startDate: startDate,
                    endDate: this.getFinishAt(startDate,period.id),
                    period: period?.name,
                    period_id: period?.id
                }
                this.$store.commit('SET_DATE_INFO', dateInfo);

                Vue.set(this.loadMoreOptions, order.id + parseInt(order.typeId), false);

                this.$store.commit('SET_SEARCH_PARAMS', params);
                this.$store.commit('SET_INFO_ORDER', {offer: offer, requestData: requestData});
                this.$store.commit('SET_FULL_INFO_ORDER', fullInfoOrder);
                this.$store.commit('SET_CAR_INFO', requestData.car);

                if(isNewProlong){
                    this.loadProcessNew = false;
                    Vue.set(this.loadMoreOptions, order.id + parseInt(order.typeId), false);
                    this.$router.push({name: `osagoResults`, query: params});
                }else{
                    if(step == 1) {
                        this.$router.push({name: 'orderSteps', query: {from: 'history'}});
                    }
                    else if(step == 2){
                        this.$router.push({name: 'orderFinal', query: {from: 'history'}});
                    }
                }
            }
            else{
                let finishedAt = null;
                let infoUser = {
                    city: {
                        id: order.insurant.cityObject.id,
                        name: order.insurant.cityObject.name_ua,
                        name_full_name_ua: order.insurant.cityObject.name_full_name_ua,
                    },
                    apartment: order.insurant.apartment,
                    house: order.insurant.house,
                    street: order.insurant.street,
                    phone: order.insurant.phone,
                    name: order.insurant.name,
                    surname: order.insurant.surname,
                    mail: order.insurant.email,
                    dateBirth: order.insurant.dateBirthAt,
                    inn: order.insurant.documentation.uin,
                    documentType: order.insurant.documentation.documentType,
                    passportNumber: order.insurant.documentation.passportNumber,
                    passportCompany: order.insurant.documentation.issuedBy,
                    passportDate: order.insurant.documentation.dateOfIssue,
                    internationalPassport: order.insurant.documentation.internationalPassport,
                }

                let searchInfo = {
                    from: {
                        id: order.offer.fromCity.id,
                        name: order.offer.fromCity.name_ua,
                        name_full_name_ua: order.offer.fromCity.name_full_name_ua,
                    },
                    to: {
                        id: order.offer.toCity.id,
                        name: order.offer.toCity.name_ua,
                        name_full_name_ua: order.offer.toCity.name_full_name_ua,
                    },
                    endDate: order.contract.finishedAt,
                    startDate: order.contract.startedAt,
                    ticketNumber: order.offer.ticketNumber,
                    ticketPrice: order.offer.ticketPrice,
                }

                this.$store.commit('SET_BUS_SEARCH_PARAMS', searchInfo);
                this.$store.commit('SET_BUS_INFO_USER', infoUser);
                this.$store.commit('CLEAR_BUS_FULL_ORDER');

                if(step == 1) {
                    this.$router.push({name: 'busSteps'});
                }
                else if(step == 2){
                    this.$router.push({name: 'busFinnaly', query: {from: 'history'}});
                }
            }
        },
        calcDiscountData(order){
            if(order.discountDetails){
                return {discountPercent:order.discountPercent, dynamicDiscountPrice: order.discountDetails.dynamicDiscountPrice, dynamicDiscountPercent:order.discountDetails.dynamicDiscountPercent, dynamicDiscountedSum:order.discountDetails.dynamicDiscountedSum, dynamicEarnings: order.discountDetails.dynamicEarnings, earnings: order.discountDetails.earnings};
            }else{
                return null;
            }
        },
        getFinishAt(startDate,periodId){
            const endDate = moment(startDate, 'DD.MM.YYYY').add(periodId, (periodId == 15 ? 'days' : 'months')).subtract(1, 'days');
            return endDate.format('DD.MM.YYYY');
        },
        getOrderEndDate(startDate, periodId) {
            const endDate = moment(startDate, 'DD.MM.YYYY').add(periodId, (periodId == 15 ? 'days' : 'months'));
            return endDate.utc(true).unix();
        },
        getOrderOtkDate(startDate, periodId) {
            const endDate = moment(startDate, 'DD.MM.YYYY').add(periodId, (periodId == 15 ? 'days' : 'months')).subtract(1, 'days');
            //console.log('getOrderOtkDate', endDate.utc(true).unix())
            return endDate.utc(true).unix();
        },
        downloadDraft: async function(order) {
            Vue.set(this.loadMoreOptions, order.id + parseInt(order.typeId), true);

            let pdf = await downloadPdf(`/api/orders/draft/${this.$store.getters.USER.id}/${order.id}`);

            if (pdf?.data) {
                FileDownload(pdf.data, 'draft.pdf');
            }

            Vue.set(this.loadMoreOptions, order.id + parseInt(order.typeId), false);
        },
        findMatchingCoverage(dgo, listDgo){
                return listDgo.find(item => parseInt(item.coverage) === parseInt(dgo.compensation));
        },
        fillHumans: function(insurants){
            insurants.forEach((insurant) => {
                const birthDate = moment(insurant.dateBirthAt, 'DD.MM.YYYY')
                const age = moment().diff(birthDate, 'years');
                if (age < 1) {
                    this.humans[0].value++;
                    this.humans[0].birthDates.push(insurant.dateBirthAt);
                    this.birthDates.push(insurant.dateBirthAt);
                } else if (age < 3) {
                    this.humans[1].value++;
                    this.humans[1].birthDates.push(insurant.dateBirthAt);
                    this.birthDates.push(insurant.dateBirthAt);
                } else if (age < 18) {
                    this.humans[2].value++;
                    this.humans[2].birthDates.push(insurant.dateBirthAt);
                    this.birthDates.push(insurant.dateBirthAt);
                } else if (age < 70) {
                    this.humans[3].value++;
                    this.humans[3].birthDates.push(insurant.dateBirthAt);
                    this.birthDates.push(insurant.dateBirthAt);
                } else if (age < 76) {
                    this.humans[4].value++;
                    this.humans[4].birthDates.push(insurant.dateBirthAt);
                    this.birthDates.push(insurant.dateBirthAt);
                } else if (age < 80) {
                    this.humans[5].value++;
                    this.humans[5].birthDates.push(insurant.dateBirthAt);
                    this.birthDates.push(insurant.dateBirthAt);
                }
            })
        },
        showDraft: async function (order) {
            if (parseInt(order.typeId) === 5) {
                return this.showTouristDraft(order);
            }

            if (parseInt(order.typeId) === 6) {
                let userId = this.$store.getters.USER.id;
                return window.open(`/api/orders/draft/${userId}/${order.id}?autolawyer=true`);
            }

            downloadOrder(this.$store.getters.USER.id, order.id);
        },
        showContract: async function (order) {
            Vue.set(this.loadMoreOptions, order.id + parseInt(order.typeId), true);

            let response = '';

            if (parseInt(order.typeId) === this.orderTypes['bus_insurance']) {
                response = await downloadBusContract(order.contractId);
            } else if(parseInt(order.typeId) === this.orderTypes['green_card']){
                response = await downloadGreenCardContract(order.contractId, order.typeId);
            } else if(parseInt(order.typeId) === this.orderTypes['dgo']) {  // todo DDGO
                response = await downloadContract(order.contractId, order.typeId);
            } else if(parseInt(order.typeId) === this.orderTypes['autolawyer']) {
                let userId = this.$store.getters.USER.id;
                Vue.set(this.loadMoreOptions, order.id + parseInt(order.typeId), false);
                return showContractFile(userId, order.id, 'autolaywer');
            } else if(parseInt(order.typeId) === this.orderTypes['tourism']) {
                response = await downloadTourismContract(order.contractId);
            } else {
                response = await downloadContract(order.contractId, order.typeId);
            }

            Vue.set(this.loadMoreOptions, order.id + parseInt(order.typeId), false);

            if (response?.data?.data?.contract) {
                order.contract.extraFiles = response?.data?.data?.extraFiles || null;
                window.open(response.data.data.contract);
            } else {
                this.$alert('Спробуйте пізніше');
            }
            return order;
        },
        goToOrderInfo: function (order) {
            if(parseInt(order.typeId) == 5){
                this.$router.push({name: 'history_insuranceTourismInfo', params: {orderId: order.id, typeId: parseInt(order.typeId)}})
            }else if(parseInt(order.typeId) == 3){
                this.$router.push({name: 'history_insuranceGreenCardInfo', params: {orderId: order.id, typeId: parseInt(order.typeId)}})
            }else{
                this.$router.push({name: 'history_insuranceOsagoInfo', params: {orderId: order.id, typeId: parseInt(order.typeId)}})
            }

        },
        conclude: async function(order) {
            await this.$swal({
                title: 'Ви дійсно бажаєте оформити цей договір?',
                type: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Ні, не варто!',
                confirmButtonText: 'Так, звісно!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    Vue.set(this.loadMoreOptions, order.id + parseInt(order.typeId), true);
                    if(parseInt(order.typeId) == 1) {
                        concludeOrder(order.id)
                            .then((response) => {
                                Vue.set(this.loadMoreOptions, order.id + parseInt(order.typeId), false);

                                if (response?.data?.status === 'success') {
                                    this.$alert("ОСЦПВ вдало оформлено!", '',);
                                    this.$router.push({name: "history"});
                                    this.$emit('init-orders-list');

                                    return true;
                                }

                                this.$alert("ОСЦПВ не оформлено!", '', 'error');
                            }).catch((error) => {
                            Vue.set(this.loadMoreOptions, order.id + parseInt(order.typeId), false);
                            if (error?.response?.data?.data?.orderId[0].includes('недостатньо коштів')) {
                                this.$alert(error?.response?.data?.data?.orderId[0], '', 'error');
                            } else {
                                this.$alert("ОСЦПВ не оформлено!", '', 'error');
                            }
                        });
                    }else if(parseInt(order.typeId) == 3){
                        let data = {
                            orderId: order.id,
                        };
                        contractGreenCard(data).then((response) => {
                            Vue.set(this.loadMoreOptions, order.id + parseInt(order.typeId), false);
                            if (response?.data?.status === 'success' && !!response.data.data[0].contractId) {
                                this.$alert("Зелена картка вдало оформлена!", '',);
                                this.$router.push({name: "history"});
                                this.$emit('init-orders-list');

                                return true;
                            }

                            this.$alert("Зелена Картка не оформлена", '', 'error');
                        }).catch((error) => {
                            Vue.set(this.loadMoreOptions, order.id + parseInt(order.typeId), false);
                            if (error?.response?.data?.status == 'error') {
                                this.$alert("Зелена Картка не оформлена", '', 'error');
                            }
                            else if (error?.response?.data?.data?.orderId[0]?.includes('недостатньо коштів')) {
                                this.$alert(error?.response?.data?.data?.orderId[0], '', 'error');
                            } else {
                                this.$alert("Зелена Картка не оформлена", '', 'error');
                            }
                        });
                    }
                }
            })
        },
        openMtsbuLink: function (order) {
            window.open(this.showMtsbuLink(order), '_blank');
        },
        showAllOrderPaymentWays: async function(order) {
            this.$refs.modalGetAllPaymentWays.open(order.id, order.allPrice, order);
        },
        getPaymentInvoice: async function(order) {
            this.$refs.modalGetInvoiceOrder.open(order.id, this.showPrice(order, true)); //this.showAllPrice(order)
        },
        getInstantLoan: async function(order) {
            this.$refs.modalGetInstantLoan.open(order.id, this.showAllPrice(order));
        },
        showMtsbuLink: function(order) {
            return order?.contract?.mtsbuCodeLink || ( order.mtsbuCodeLink || '');
        },
        copy: async function(copiedOrder, isProlong = false, isNewProlong = false) {
            let order = Object.assign({}, copiedOrder);
            Vue.set(this.loadMoreOptions, order.id + parseInt(order.typeId), true);
            this.$store.commit('SET_CLIENT', null);
            this.isCopyProcess = true;

            let offer = {};
            const carType = this.getCarType(order);
            let period_id =  order.offer.period_id ?? this.getOrderContractPeriod(order).id;
            let otkPeriod = period_id ? period_id : this.getOrderContractPeriod(order).id;
            let startDate = this.getStartDate(order, isProlong);

            if (parseInt(order.typeId) === 1) {
                const birthDate = new Date(new Date().setFullYear(new Date().getFullYear() - 35)).toLocaleDateString('uk-UA');
                const offerDriverBirthDate = order.offer.calcOptions?.car?.driverBirthdayAt ? moment(order.offer.calcOptions?.car?.driverBirthdayAt, "YYYY-MM-DD").format('DD.MM.YYYY') : null;
                let dataCalculate = {
                    franchise: 0, //parseInt(order.offer.franchise),
                    autoCategoryType: order.insuranceObject.autoCategory,
                    registrationPlaceId: order.offer.cityId,
                    zone: order.insuranceObject?.registrationPlaceZone,
                    period_id: period_id,
                    customerPrivilege: order.insurant.privilegeTypeId ?? 1,
                    otkDate: order.offer.otkDate ? this.getOrderOtkDate(startDate, otkPeriod) : null,
                    endDate: null,
                    isTaxi: order.insuranceObject.isTaxi,
                    isEuroCar: order.insuranceObject.isEuroCar,
                    customerType: order.insurant.customerTypeId,
                    registrationType: order.registrationType,
                    months: order.usageMonthsDec,
                    usageMonths: order.usageMonths,
                    startDate: startDate,
                    car:{"vin":order?.insuranceObject?.vin || null,
                         "year":order?.insuranceObject?.year || null,
                         "kilometers":null,
                         "birthdayAt":offerDriverBirthDate ?? (order?.insurant?.dateBirthAt || birthDate),
                         "brand":"",
                         "model":"",
                         "number":order?.insuranceObject?.number || null,
                         "additional_parameters":{"capacity":null,"ownWeight":null,"totalWeight":null,"kvt":null,"numberOfSeats":null}
                         }
                };

                if (!this.isPermanentRegistrationType(dataCalculate.registrationType)) {
                    dataCalculate.endDate = this.getOrderEndDate(startDate, period_id);
                }

                let response = await calculate(dataCalculate);
                offer = this.$_.find(response?.data?.data, (item) => {
                    return item.company.id === order.companyInfo.companyId && item.programmName === order.offer.programmName;
                });

                order.offer.dateFromMax = offer?.dateFromMax;

                if (!offer?.offerId && !isNewProlong) {
                    this.loadProcessCurrent = false;
                    this.loadProlongationModal = true;
                    this.isCopyProcess = false;

                    let params = {
                        fromProlongation: true,
                        isTaxi: order.insuranceObject.isTaxi ? 1 : 0,
                        isEuroCar: order.insuranceObject.isEuroCar ? 1 : 0,
                        experience: this.experience,
                        franchise: 'all',
                        autoCategoryType: order.insuranceObject.autoCategory,
                        registrationPlaceId: order.insuranceObject.registrationPlaceId,
                        cityName: order.insuranceObject.registrationPlace,
                        otkDate: order.offer.otkDate ? this.getOrderOtkDate(startDate, otkPeriod) : null, //period_id
                        endDate: null,
                        period_id: period_id,
                        withoutOtk: order.withoutOtk ? 1 : 0,
                        startDate: startDate,
                        dateFromMax: offer?.dateFromMax,
                        carTypeInfo: carType,
                        cityInfo: order.insurant?.cityObject,
                        zone: order.insuranceObject?.registrationPlaceZone,
                        customerPrivilege: order.insurant.privilegeTypeId ?? null,
                        registrationType: order.registrationType,
                        customerType: order.insurant?.customerTypeId,
                        car: {
                            vin: order.insuranceObject.vin,
                            year: order.insuranceObject.year,
                            brand: '',
                            model: '',
                            number: order.insuranceObject.number,
                            birthdayAt: offerDriverBirthDate ?? (order?.insurant?.dateBirthAt || birthDate),
                            additionalParameters: order.insuranceObject.additionalParameters,
                        }
                    };

                    if (!this.isPermanentRegistrationType(params.registrationType)) {
                        params.endDate = this.getOrderEndDate(startDate, period_id);
                    }

                    let paramsCar = {
                        vin: order.insuranceObject.vin,
                        year: order.insuranceObject.year,
                        brand: '',
                        model: '',
                        number: order.insuranceObject.number,
                        birthdayAt: offerDriverBirthDate ?? (order?.insurant?.dateBirthAt || birthDate),
                        additionalParameters: order.insuranceObject.additionalParameters,
                    };

                    let period = this.getOrderContractPeriod(order);

                    let dateInfo = {
                        startDate: startDate,
                        endDate: this.getFinishAt(startDate,period.id),
                        period: period?.name,
                        period_id: period?.id
                    }

                    params.endDate ?? params.otkDate;
                    this.$store.commit('SET_DATE_INFO', dateInfo);

                    this.$store.commit('SET_SEARCH_PARAMS', params);
                    this.$store.commit('SET_OSAGO_OFFERS', null);
                    this.$store.commit('SET_CAR_INFO', paramsCar);
                    this.$router.push({name: `osagoResults`, query: params});

                    Vue.set(this.loadMoreOptions, order.id + parseInt(order.typeId), false);

                    return
                }

                // ищем автоюриста в новом оффере
                const autolawyerData = this.getAutolawyerData(offer, order);

                order.offer.autolawyerId = autolawyerData?.id;
                order.offer.autolawyerPrice = autolawyerData?.price;
                order.offer.autolawyerProgram = autolawyerData?.program;
                order.offer.autolawyerZone =  autolawyerData?.zone;
                order.offer.autolawyerAutoCategoryType =  autolawyerData?.auto_category_type;
                order.autolawyerPrice = autolawyerData?.price;
                if (order.autolawyer) {
                    order.autolawyer.id = autolawyerData?.id;
                    order.autolawyer.price = autolawyerData?.price;
                    order.autolawyer.program = autolawyerData?.program;
                    order.autolawyer.zone = autolawyerData?.zone;
                    order.autolawyer.auto_category_type = autolawyerData?.auto_category_type;
                }

                order.offer.listAutolawyer = offer?.listAutolawyer || [];

                dataCalculate.cityInfo = order.insurant.cityObject;
                dataCalculate.cityName = order.insurant.cityObject?.name_full_name_ua || order.insurant.city;
                dataCalculate.car = {
                    vin: order.insuranceObject.vin,
                    year: order.insuranceObject.year,
                    brand: order.insuranceObject.brand,
                    model: order.insuranceObject.model,
                    number: order.insuranceObject.number,
                    withoutVin: order.insuranceObject.withoutVin,
                    birthdayAt: offerDriverBirthDate ?? (order?.insurant?.dateBirthAt || birthDate),
                };

                let period = this.getOrderContractPeriod(order);
                let dateInfo = {
                    startDate: startDate,
                    endDate: this.getFinishAt(startDate,period.id),
                    period: period?.name,
                    period_id: period?.id
                }

                this.$store.commit('SET_DATE_INFO', dateInfo);

                this.$store.commit('SET_SEARCH_PARAMS', dataCalculate);
                this.$store.commit('SET_OSAGO_OFFERS', null);

            }

            if (parseInt(order.typeId) === 3) {
                let startDateGreen = startDate;

                let paramsGreenCard = {
                    carType: order.insuranceObject.autoCategory,
                    country: order.offer.country.id,
                    periodOption: period_id,
                    startDate: startDateGreen,
                    userType: order.insurant.customerTypeId
                }

                let responseGreen = await calculateGreenCard(paramsGreenCard.country, paramsGreenCard.carType, paramsGreenCard.userType, paramsGreenCard.startDate, paramsGreenCard.periodOption);

                offer = this.$_.find(responseGreen?.data?.data, (item) => {
                    return item.company.id === order.companyInfo.companyId;
                });

                order.offer.dateFromMax = offer?.dateFromMax;
                if (!offer?.offerId && !isNewProlong) {
                    let userTypeList = this.$store.getters.CUSTOMER_TYPES;
                    let paramsGreenCardSearch = {
                        dateFromMax: offer?.dateFromMax,
                        carType: carType,
                        country: order.offer.country,
                        periodOption: this.periodOptions.find((item) => item.id === period_id),
                        startDate: startDateGreen,
                        userType: userTypeList.find(option => option.id === order.offer.userType),
                    }

                    let greenCardInfoUser = {
                        documentType: order.insurant.documentation.documentType,
                        identificationCode: order.insurant.documentation.uin,
                        internationalPassport: (order.insurant.documentation.documentType == 2) ? order.insurant.documentation.number : null,
                        erdpyCode: order.insurant.documentation.uin,
                        name: order.insurant.name,
                        surname: order.insurant.surname,
                        dateBirth: order.insurant.dateBirthAt,
                        phone: order.insurant.phone,
                        mail: order.insurant.email,
                        city: order.insurant.city,
                        street: order.insurant.street,
                        house: order.insurant.house,
                        apartment: order.insurant.apartment,

                        passportSerial: order.insurant.documentation.serial,
                        passportNumber: order.insurant.documentation.number,
                        passportCompany: order.insurant.documentation.issuedBy,
                        passportDate: order.insurant.documentation.dateOfIssue,
                        withoutIdentificationCode: order.insurant.documentation.withoutUin,
                        withoutDocuments: order.insurant.documentation.withoutDocuments
                    };

                    this.$store.commit('SET_GREENCARD_SEARCH_PARAMS', paramsGreenCardSearch);
                    this.$store.commit('SET_GREENCARD_INFO_USER', greenCardInfoUser);
                    this.$router.push({name: `greenCardResults`, query: {from: 'history'}});

                    Vue.set(this.loadMoreOptions, order.id + parseInt(order.typeId), false);

                    return
                }
            }

            if (parseInt(order.typeId) === 5) {
                this.humans = [
                    {name: 'Діти до 1 року ', value: 0, min: 0, max: 1, birthDates: [], id:1},
                    {name: 'Діти до 3 років ', value: 0, min: 2, max: 3,  birthDates: [], id:2},
                    {name: 'Підлітки до 18 років ', value: 0, min: 4, max: 18,  birthDates: [], id:3},
                    {name: 'Дорослі до 69 років ', value: 0, min: 19, max: 69, birthDates: [], id:4},
                    {name: 'Дорослі 70-75 років ', value: 0, min: 70, max: 75, birthDates: [], id:5},
                    {name: 'Літні 76-100 років ', value: 0, min: 76, max: 100, birthDates: [], id:6}
                ];
                this.birthDates = [];
                this.fillHumans(order.insurants);

                const calculateDate =  this.calculateDate(startDate, order.offer.period_option, order.offer.yearProgram);

                const paramsTourism = {
                    date: calculateDate.startDate,
                    end: calculateDate.endDate,
                    days: order.offer.period_option,
                    country: {id:order.offer.country.id,ewa_id:order.offer.country.ewa_id,name:order.offer.country.name},
                    tourists: order.offer.tourists,
                    humans: this.humans,
                    yearProgram: order.offer.yearProgram,
                    birthDates: this.birthDates,
                    filters: {
                        "franchise": "",
                        "options_list": [],
                        "activities": {
                            "name": "Без активності",
                            "value": "no_activities"
                        },
                        "activitiesItem": "Без активностей"
                    },
                    activities: "no_activities",
                };

                let responseTourism = await calculateTourism(paramsTourism);

                offer = this.$_.find(responseTourism?.data?.data, (item) => {
                    return item.company.id == order.companyInfo.companyId && item.tripProgram == order.offer.trip_program && item.tourists == order.offer.tourists && parseInt(item.coverage) == parseInt(order.offer.coverage) && parseInt(item.franchise) == parseInt(order.offer.franchise) && item.covid_help == order.offer.covid_help;
                });

                let userInfo = {
                    "identificationCode":order.insurant.documentation.uin,
                    "withoutIdentificationCode":order.insurant.documentation.withoutUin,
                    "name":order.insurant.name,
                    "surname":order.insurant.surname,
                    "dateBirth":order.insurant.dateBirthAt,
                    "phone":order.insurant.phone,
                    "mail":order.insurant.email,
                    "city":order.insurant.cityObject,
                    "street":order.insurant.street,
                    "house":order.insurant.house,
                    "apartment":order.insurant.apartment,

                    "documentation": {
                        //TODO текущее значение нобходимо будет брать с словаря(api)
                        documentType: order.insurant.documentation.documentType,
                        type: order.insurant.documentation.type,
                        serial: order.insurant.documentation.serial,
                        number: order.insurant.documentation.number,
                        issuedBy: order.insurant.documentation.issuedBy,
                        dateOfIssue: order.insurant.documentation.dateOfIssue,
                        endDateOfIssue: order.insurant.documentation.endDateOfIssue,
                    },
                    "documentType":order.insurant.documentation.documentType,
                    "passportSerial":order.insurant.documentation.serial,
                    "passportNumber":order.insurant.documentation.number,
                    "passportDate":order.insurant.documentation.dateOfIssue
                };
                let TouristsList = [];

                order.insurants.forEach((insurant) => {
                    TouristsList.push({ name: insurant.name, surname: insurant.surname, dateBirth: insurant.dateBirthAt, identificationCode: insurant.documentation.uin,
                        withoutIdentificationCode:insurant.documentation.withoutUin,
                        passportSerial: insurant.documentation.serial, passportNumber: insurant.documentation.number, passportDate: insurant.documentation.dateOfIssue, documentType: insurant.documentation.type, passportIssuedBy: insurant.documentation.issuedBy,
                        passportEndDate: insurant.documentation.endDateOfIssue,});
                })

                if (!offer?.offerId && !isNewProlong) {
                    this.$store.commit('CLEAR_TOURISM_FULL_ORDER');
                    this.$store.commit('CLEAR_TOURISM_INFO_TOURISTSLIST');
                    this.$store.commit('CLEAR_SEARCH_TOURISM_PARAMS');
                    this.$store.commit('CLEAR_TOURISM_INFO_OFFER');
                    this.$store.commit('CLEAR_TOURISM_INFO_USER');
                    this.$store.commit('SET_SEARCH_TOURISM_PARAMS', paramsTourism);
                    this.$store.commit('SET_TOURISM_INFO_USER', userInfo);
                    this.$store.commit('SET_TOURISM_INFO_TOURISTSLIST', TouristsList);

                    this.$router.push({name: `tourismResult`});

                    Vue.set(this.loadMoreOptions, order.id + parseInt(order.typeId), false);

                    return;
                }
            }

            if (offer?.offerId) {
                order.price = order.allPrice = offer.price;
                order.discountPrice = 0;
                order.offer.id = offer.offerId;
                order.company = offer.company;
                order.earnings = offer.earnings;
                order.offer.available_documents = offer.available_documents ?? null;
            }
            Vue.set(this.loadMoreOptions, order.id + parseInt(order.typeId), false);

            this.declare(order, true, isProlong, 1, isNewProlong);
        },
        getAutolawyerData(newOffer, oldOrder) {
            if ((oldOrder.autolawyerPrice && oldOrder.autolawyer && oldOrder.autolawyer?.price) && newOffer.listAutolawyer.length) {
                const foundAutolawyer = newOffer.listAutolawyer.find(item => item.program === oldOrder.autolawyer.program);
                return foundAutolawyer ? foundAutolawyer : null;
            }
            return null;
        },
        calculateDate(startDate, period_option, yearProgram) {
            // Преобразуем строку в объект moment
            let startMoment = moment(startDate, 'DD.MM.YYYY');

            // Получаем сегодняшнюю дату
            const today = moment().startOf('day');

            // Если startMoment меньше или равен сегодняшней дате, установим startMoment на завтрашний день
            if (startMoment.isSameOrBefore(today)) {
                startMoment = startMoment.add(1, 'day');
            }

            // Установим endMoment на period_option дней после startMoment
            let endMoment = startMoment.clone().add(period_option, 'days');

            // Если установлен флаг yearProgram, увеличим endMoment на год
            if (yearProgram) {
                endMoment = startMoment.clone().add(1, 'year').subtract(1, 'day');
            }else{
                endMoment = endMoment.subtract(1, 'day');
            }

            // Возвращаем новые даты в формате 'дд.мм.гггг'
            return {
                startDate: startMoment.format('DD.MM.YYYY'),
                endDate: endMoment.format('DD.MM.YYYY')
            };
        },
        getStartDateForCopy: function (order) {

            const orderContractStart = moment(order.contract?.startedAt, "DD.MM.YYYY").unix();
            const tomorrowDate = moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()+1));//.utc();
            let startDateUnix = tomorrowDate.unix();
            let startDate = tomorrowDate.format('DD-MM-YYYY');

            if (orderContractStart > startDateUnix) {
                startDate = moment(order.contract?.startedAt, "DD.MM.YYYY").format('DD-MM-YYYY');
            }

            return startDate;
        },
        getCarType: function(order) {
            let carType = {};

            if (parseInt(order.typeId) === 1) {
                if(order.insuranceObject.autoCategory == 'B1' || order.insuranceObject.autoCategory == 'B2' || order.insuranceObject.autoCategory == 'B3' || order.insuranceObject.autoCategory == 'B4'){
                    carType = this.carTypes[0];
                }else if(order.insuranceObject.autoCategory == 'D1' || order.insuranceObject.autoCategory == 'D2'){
                    carType = this.carTypes[1];
                }else if(order.insuranceObject.autoCategory == 'C1' || order.insuranceObject.autoCategory == 'C2'){
                    carType = this.carTypes[2];
                }else if(order.insuranceObject.autoCategory == 'E' || order.insuranceObject.autoCategory == 'F'){
                    carType = this.carTypes[3];
                }else if(order.insuranceObject.autoCategory == 'A1' || order.insuranceObject.autoCategory == 'A2'){
                    carType = this.carTypes[4];
                }else if(order.insuranceObject.autoCategory == 'B5'){
                    carType = this.carTypes[5];
                }
            }

            if (parseInt(order.typeId) === 3) {
                if(order.insuranceObject.autoCategory == 'A' || order.insuranceObject.autoCategory == 'A1' || order.insuranceObject.autoCategory == 'A2' || order.insuranceObject.autoCategory == 'A3' || order.insuranceObject.autoCategory == 'A4'){
                    carType = this.carTypesGreenCard[0];
                }else if(order.insuranceObject.autoCategory == 'E' || order.insuranceObject.autoCategory == 'E1' || order.insuranceObject.autoCategory == 'E2'){
                    carType = this.carTypesGreenCard[1];
                }else if(order.insuranceObject.autoCategory == 'C' || order.insuranceObject.autoCategory == 'C1' || order.insuranceObject.autoCategory == 'C2'){
                    carType = this.carTypesGreenCard[2];
                }else if(order.insuranceObject.autoCategory == 'E' || order.insuranceObject.autoCategory == 'F'){
                    carType = this.carTypesGreenCard[3];
                }else if(order.insuranceObject.autoCategory == 'B' || order.insuranceObject.autoCategory == 'B1' || order.insuranceObject.autoCategory == 'B2'){
                    carType = this.carTypesGreenCard[4];
                }
            }

            return carType;
        },
        showAutolawyerPrice: function(order) {
            let autolwyerPrice = order.autolawyerPrice ?? order.offer.autolawyerPrice;
            return autolwyerPrice ? Number(autolwyerPrice) : autolwyerPrice;
        },
        showTypeInsurance: function(order) {
            let manualProdType = "";
            let orderType = this.allOrderTypes.find(type => type.id === parseInt(order.typeId));
            if(order.manualTypeId){
                manualProdType = " (" + order.manualProductType + ")";
            }
            return orderType ? orderType.name + manualProdType : 'Неизвестный тип страхования';
        },

        isProlongationOrder: function (order) {
            return order.isProlongation;
        },
        showMtsbuCode: function(order) {
            if (parseInt(order.typeId) == this.orderTypes['dgo'] && order?.contractDgo?.mtsbuCode){
                return order?.contractDgo?.mtsbuCode;
            } else {
                return order?.contract?.mtsbuCode || (order.mtsbuCode || '');
            }
        },
        showNameInsured: function(order) {
            return order?.insured?.fullName || ''
        },
        showStartedAtContract: function(order) {
            return moment(order.contract.startedAt, "DD.MM.YYYY").format('DD.MM.YYYY');
        },
        showFinishedAtContract: function(order) {
            return moment(order.contract.finishedAt, "DD.MM.YYYY").format('DD.MM.YYYY');
        },
        showNameInsurantSurname: function(order) {
            return order?.insurant?.surname || ''
        },
        showNameInsurantName: function(order) {
            return order?.insurant?.name || ''
        },
        showNameInsurantPatronymic: function(order) {
            return order?.insurant?.patronymic || ''
        },
        showPrice: function(order, withDgo = false) {
            if (parseInt(order.typeId) == this.orderTypes['dgo']) {
                return order.dgoPrice;
            }
            if (parseInt(order.typeId) == this.orderTypes['autolawyer']) {
                return order.autolawyerPrice;
            }
            if (withDgo) {
                return (parseFloat(order.price) + parseFloat(order.dgoPrice ?? 0) + parseFloat(order.autolawyerPrice ?? 0)).toFixed(2);
            }

            return order.price;
        },
        showDiscount: function(order) {
            if (parseInt(order.typeId) == this.orderTypes['dgo'] || parseInt(order.typeId) == this.orderTypes['autolawyer']) {
                return false;
            }
            return (order.discountPrice && order.discountPrice != 0) ? order.discountPrice : false;
        },
        showDgoPrice: function(order) {
            return order.dgoPrice;
        },
        isProlongation: function () {
            return this.listQuery.statusId === this.prolongationStatus.id;
        },
        isDraft: function(order) {
            if (parseInt(order.typeId) == this.orderTypes['dgo'] || parseInt(order.typeId) == this.orderTypes['autolawyer']) {
                return false; // заглушка пока оформляется вместе с ОСАГО
            }
            return order.statusId === this.draftStatus;
        },
        isDeclared: function(order) {
            if (parseInt(order.typeId) == this.orderTypes['dgo'] || parseInt(order.typeId) == this.orderTypes['autolawyer']) {
                return false; // заглушка пока оформляется вместе с ОСАГО
            }
            return order.statusId === this.declaredStatus;
        },
        isOverdue: function(order) {
            if (parseInt(order.typeId) == this.orderTypes['dgo']) {
                return false; // заглушка пока оформляется вместе с ОСАГО
            }
            return order.statusId === this.overdueStatus;
        },
        isConcluded: function(order) {
            return order.statusId === this.concludeStatus;
        },
        getCity: function (city) {

            if (typeof city !== 'string') return ''
            return city.charAt(0).toUpperCase() + city.slice(1).toLowerCase()
        },
        showCashBack: function(order) {
            if (parseInt(order.typeId) == this.orderTypes['dgo'] || parseInt(order.typeId) == this.orderTypes['autolawyer']) {
                return false;
            }
            return (order.cashbackPrice && order.cashbackPrice != 0) ? order.cashbackPrice : false;
        },
        getStartDate: function (order, isProlong = false) {
            const orderStartDate = order.offer.startedAt;
            const otkDate = order.offer.otkDate;
            const orderStartMoment = moment(orderStartDate, 'DD.MM.YYYY');
            let tomorrowMoment = moment().add(1, 'day').startOf('day');

            if (isProlong && order.offer.otkDate && order.prolongStatus !== 0) {
                //console.log('isProlong otk')
                tomorrowMoment =  moment(otkDate, 'DD.MM.YYYY').add(1, 'day').startOf('day');
            }

            if (orderStartMoment.isSameOrAfter(tomorrowMoment, 'day')) {
                return orderStartMoment.format('DD.MM.YYYY');
            } else {
                return tomorrowMoment.format('DD.MM.YYYY');
            }
        },
        showMtsbuCodeAdditionalInfo: function(order) {
            if(parseInt(order.typeId) == this.orderTypes['dgo'] || parseInt(order.typeId) == this.orderTypes['autolawyer']){
                return 'на основi'
            }
        },
        getOrderContractPeriod(order){
            let currentYear = parseInt(moment(order.contract.finishedAt,'DD/MM/YYYY').format('YYYY'));
            let currentMonth = parseInt(moment(order.contract.finishedAt,'DD/MM/YYYY').format('MM'));
            let currentDay = parseInt(moment(order.contract.finishedAt,'DD/MM/YYYY').format('DD'));
            let startYear = parseInt(moment(order.contract.startedAt,'DD/MM/YYYY').format('YYYY'));
            let startMonth = parseInt(moment(order.contract.startedAt,'DD/MM/YYYY').format('MM'));
            let startDay = parseInt(moment(order.contract.startedAt,'DD/MM/YYYY').format('DD'));

            let diffYears = moment(new Date((currentYear+1), new Date(currentMonth-1), new Date(currentDay-1))).diff(new Date((startYear+1), new Date(startMonth-1), new Date(startDay-1)), 'years', true);
            let diffMonths = moment(new Date((currentYear+1), new Date(currentMonth-1), new Date(currentDay-1))).diff(new Date((startYear+1), new Date(startMonth-1), new Date(startDay-1)), 'months', true);
            let diffDays = moment(new Date((currentYear+1), new Date(currentMonth-1), new Date(currentDay-1))).diff(new Date((startYear+1), new Date(startMonth-1), new Date(startDay-1)), 'days', true);

            let orderPeriod = {};
            let period;
            if(diffYears >= 1) {
                let count = Math.round(diffYears);
                let months = Math.ceil(diffMonths);
                orderPeriod.id = 12;
                if(count >= 2) {
                    orderPeriod.key = 'y'
                    orderPeriod.count = count
                    orderPeriod.name = count +' роки'
                    orderPeriod.countMonths = months;
                } else if(count >= 5) {
                    orderPeriod.key = 'y'
                    orderPeriod.count = count
                    orderPeriod.name = count +' років'
                    orderPeriod.countMonths = months;
                } else {
                    orderPeriod.key = 'y'
                    orderPeriod.count = count
                    orderPeriod.name = count +' рік'
                    orderPeriod.countMonths = months;
                }
                period = orderPeriod;
            } else if(diffDays > 15) {
                let count = Math.ceil(diffMonths);

                if(count < 5 && count > 1){
                    orderPeriod = count //+' місяці'
                }else if(count >= 5){
                    orderPeriod = count //+' місяців'
                }else{
                    orderPeriod = count //+' місяць'
                }
                period = this.periodOptions.find(item => item.id === orderPeriod)

            } else{
                let count = diffDays;
                if(count <= 15){
                    orderPeriod = 15;//= '15 днів'
                }else{
                    orderPeriod = count //+' днів'
                }
                period = this.periodOptions.find(item => item.id === orderPeriod)
            }

            return period;
        },
        downloadContract: async function (order) {
            Vue.set(this.loadMoreOptions, order.id + parseInt(order.typeId), true);

            let response = '';

            if (parseInt(order.typeId) === this.orderTypes['bus_insurance']) {
                response = await downloadBusContract(order.contractId);
            } else if(parseInt(order.typeId) === this.orderTypes['green_card']){
                response = await downloadGreenCardContract(order.contractId, order.typeId);
            }  else if(parseInt(order.typeId) === this.orderTypes['tourism']){
                response = await downloadTourismContract(order.contractId);
            } else if(parseInt(order.typeId) === this.orderTypes['dgo']) {  // todo DDGO
                response = await downloadContract(order.contractId, order.typeId);
            } else {
                response = await downloadContract(order.contractId, order.typeId);
            }

            if (response?.data?.data?.contract) {
                order.contract.extraFiles = response?.data?.data?.extraFiles || null;
                let pdf = await downloadPdf(response?.data?.data?.contract);

                if (pdf?.data) {
                    FileDownload(pdf?.data, 'contract-'+order.contract.mtsbuCode+"-"+Date.now()+'.pdf');
                }

                Vue.set(this.loadMoreOptions, order.id + parseInt(order.typeId), false);
            } else {
                Vue.set(this.loadMoreOptions, order.id + parseInt(order.typeId), false);

                this.$alert('Спробуйте пізніше');
            }
            return order;
        },
        async downloadData(order, orders, type = 1) {
            if (type == 2) {
                order = await this.downloadContract(order, order.typeId);
            } else if (type == 1) {
                order = await this.showContract(order);
            }

            return order?.contract?.extraFiles ? {"order_id":order.id, "extraFiles":order?.contract?.extraFiles} : null;
        },
        updateExtraFiles(ordersList, order) {
            ordersList.forEach(item => {
                if (item.id === order.order_id) {
                    this.$set(item.contract, 'extraFiles', order.extraFiles);
                }
            });

            return ordersList;
        },
        showModalGetInvoiceLegal(order) {
            this.$refs.modalGetInvoiceLegalOrder.open(order, this.showPrice(order, true), order.myEarnings);
        },
        showModalGetMonoPayParts(order) {
            this.$refs.showModalGetMonoPayParts.open(order.id, order.allPrice);
        },
        showAllPrice: function(order) {
            let finishPrice = 0;
            finishPrice = order.price;
            if(order.discountPrice > 0)
            {
                var realDiscountPercent = 100 - order.discountPrice * 100 / order.price;
                finishPrice = Number(finishPrice - (finishPrice / 100 * realDiscountPercent)).toFixed(2);
            }
            return finishPrice;
        },
    },
}
