const state = {
    mainTypes: {
        'writeOff': 0,
        'replenishment': 1,
        'accrual': 2,
    },
    types: {
        'transaction': 1,
        'transaction_bonus': 2,
        'transaction_referral_bonus': 4,
    },
    subtype: {
        'create_invoice_for_client': 1, // Пополнение клиентом своего счета
        'purchase_insurance': 2, // Приобретение страховки
        'withdrawal_bonus_funds': 3, // Вывод бонусных средств
        'score_reset_by_admin': 4, // обнуление счета со стороны админа
        'bonus_score_reset_by_admin': 5, // обнуление бонусного счета со стороны админа,
        'create_invoice_for_client_by_admin': 6, // платеж пополнения счёта пользователя созданный админом
        'purchase_insurance_through_payment_system': 7, // Оплата страховой произошла на строне клиента, подобный момент происходит на партнерах,
        'create_invoice_for_partner_by_main_user': 8, // платеж пополнения счёта партнера созданный главным пользотвалем.
        'payment_of_insurance_through_invoicing': 9, // оплата страховой через выставление счёта.
        'create_withdraw_invoice_for_client_by_admin': 10, // снятие счёта пользователя созданный админом
        'writing_off_commission_for_terminated_contract_osago': 11, //списание комиссии за расторгнутый договор осаго
        'writing_off_commission_for_terminated_contract_dgo': 12, //списание комиссии за расторгнутый договор дго
        'refund_of_funds_for_canceled_contract': 13, //Возвращение денег за договор
        'refund_of_funds_for_test_contract': 14, //возврат денег за тестовый договор
        'writing_off_commission_for_test_contract_osago': 15, //списание комиссии за тестовую страховую договор осаго
        'writing_off_commission_for_test_contract_dgo': 16, //списание комиссии за тестовую страховую договор дго
        'purchase_bus_insurance': 17, // приобретение страховки для автобуса
        'purchase_green_card_insurance': 18, // приобретение страховки для green_card
        'writing_off_commission_for_terminated_green_card_insurance' : 19, //списание комиссии за расторгнутый договор зеленой карты
        'payment_of_insurance_by_legal_entity' : 20, //Оплата страховой через счет-фактуру (pdf)
        'purchase_tourism_insurance' : 21, // приобретение страховки для туризма,
        'writing_off_commission_for_terminated_tourism_insurance' : 22, //списание комиссии за расторгнутый договор туризма
        'payment_of_insurance_by_monobank_pay_parts' : 23, // монобанк
        'create_invoice_for_main_user_by_partner' : 24, // платеж пополнения счёта главного пользотваля созданный партнером.
        'refund_of_funds_through_payment_system' : 25, // Возврат средств которые пришли на стороное клиента (откат на транзакцию №7).
        'writing_off_commission_for_terminated_contract_autolawyer': 26, //списание комиссии за расторгнутый договор автоюрист
        'writing_off_referral_commission_for_terminated_contract_osago': 27, //списание комиссии за расторгнутый договор осаго
        'writing_off_referral_commission_for_terminated_contract_dgo': 28, //списание комиссии за расторгнутый договор дго
        'writing_off_referral_commission_for_terminated_contract_green_card_insurance': 29, //списание комиссии за расторгнутый договор дго
        'writing_off_referral_commission_for_terminated_contract_tourism_insurance': 30, //списание комиссии за расторгнутый договор дго
        'writing_off_commission_for_terminated_contract_manual' : 31, //списание комиссии за расторгнутый договор ручного оформления
        'purchase_manual_insurance' : 32, // приобретение страховки для ручных оформлений,
        'earnings_for_paying_a_fine': 33, //Заробіток за штраф
        'create_invoice_for_partner_by_admin': 34, // платеж пополнения счёта партнера созданный админом
        'create_withdraw_invoice_for_partner_by_admin': 35, // снятие счёта партнера созданный админом
    }
};

const getters = {
    ACCOUNT_CASH_HISTORY: state => state
};

export default {
    state,
    getters
};

