<template>
    <div>
        <div v-if="dialogVisible" class="modal-mask policy-bill__modal">
            <div class="custom-modal-wrapper">
                <div class="custom-modal-container">
                    <div class="policy-bill">
                        <div v-if="this.preloader" class="modal-preloader">
                            <div class="spinner"></div>
                        </div>
                        <div class="policy-bill__heading">
                            Оберіть варіант оплати
                            <el-tooltip class="item" effect="dark">
                                <i class="icon question"></i>
                                <div slot="content">
                                    Звертаємо Вашу увагу, для автоматичного зарахування коштів на особистий кабінет потрібно вказати призначення платежу:
                                    <br>За оформлення страхових полісів, ##1.  Якщо Ви не вказали ##1, будь ласка, відправте квитанцію у Ваш чат у Вайбері або через кнопку спілкування з менеджером в особистому кабінеті для нарахування платежу на баланс в особистому кабінеті.
                                </div>
                            </el-tooltip>
                        </div>

                        <hr class="hr-grey">
                        <a href="javascript:void(0)" @click="close()" class="hide">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17 1L1 17" stroke="black" stroke-width="2"/>
                                <path d="M1 1L17 17" stroke="black" stroke-width="2"/>
                            </svg>
                        </a>

                        <button @click="showModalGetInvoiceOrder()" class="btn btn-warning payments-ways-btn" type="button">
                        <span class="payments-icon">
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.11511 25.2118L0.754317 24.903C-0.124429 24.1527 -0.278858 22.7847 0.521989 21.9059L9.9217 11.1628C10.672 10.2841 12.04 10.1297 12.9187 10.9305L13.2795 11.2407C14.1583 11.9896 14.3127 13.359 13.5118 14.2364L4.11214 24.9795C3.36186 25.8582 2.04579 25.9607 1.11511 25.2118Z" fill="url(#paint0_linear_429_676)"/>
                            <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M8.65769 13.0229L8.47593 12.8151L7.03003 14.4688L7.18446 14.6491C9.6649 17.1282 8.60576 19.6333 8.03724 20.512L8.2436 20.2797C8.57979 19.8916 9.09638 19.2984 9.63757 18.6534C10.2061 17.6708 11.0069 15.3461 8.65769 13.0229Z" fill="url(#paint1_linear_429_676)"/>
                            <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M11.7556 16.2508L12.1437 15.8121C12.1697 15.7602 12.2202 15.7082 12.2462 15.6563C11.8335 16.121 11.3948 16.613 10.9807 17.1022C11.2909 16.7674 11.5492 16.4831 11.7556 16.2508Z" fill="url(#paint2_radial_429_676)"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5652 11.4976L4.16277 0.755892C3.41386 -0.12422 2.04449 -0.280016 1.16711 0.523565L0.806319 0.831057C-0.0724267 1.57997 -0.226856 2.94934 0.573991 3.82672L8.50184 12.8137L8.68223 13.0214C11.0328 15.372 10.232 17.6953 9.63888 18.6766C10.1035 18.134 10.5682 17.5915 11.0069 17.1008C11.421 16.6102 11.8597 16.1196 12.2724 15.6549C12.6865 15.1903 13.0732 14.7516 13.3315 14.466C14.2349 13.5886 14.3647 12.375 13.5639 11.4976H13.5652Z" fill="url(#paint3_linear_429_676)"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0472 25.2118L12.6864 24.903C11.8077 24.1527 11.6533 22.7847 12.4541 21.9059L21.8538 11.1628C22.6027 10.2841 23.9721 10.1297 24.8495 10.9305L25.2116 11.2407C26.089 11.9896 26.2448 13.359 25.444 14.2364L16.0429 24.9795C15.294 25.8582 13.9779 25.9607 13.0472 25.2118Z" fill="url(#paint4_linear_429_676)"/>
                            <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M20.6921 12.9189L20.5117 12.7126L19.0645 14.3662L19.2202 14.5466C21.6993 17.0257 20.6415 19.5307 20.0717 20.4095L20.2794 20.1771C20.6142 19.789 21.1308 19.1945 21.6734 18.5495C22.2419 17.5682 23.0427 15.2422 20.6921 12.9189Z" fill="url(#paint5_linear_429_676)"/>
                            <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M23.6616 16.2508L24.0497 15.8121C24.0743 15.7602 24.1263 15.7082 24.1522 15.6563C23.7395 16.121 23.3008 16.613 22.8867 17.1022C23.1969 16.7674 23.4812 16.4831 23.6616 16.2508Z" fill="url(#paint6_radial_429_676)"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M25.4699 11.4976L16.0688 0.755892C15.3199 -0.12422 13.9519 -0.280016 13.0731 0.523565L12.7123 0.831057C11.8336 1.58134 11.6791 2.95071 12.48 3.82809L20.4078 12.8151L20.5882 13.0228C22.9388 15.3734 22.138 17.6967 21.5449 18.6779C22.0095 18.1354 22.4742 17.5928 22.9129 17.1022C23.3256 16.6116 23.7657 16.121 24.1784 15.6563C24.5911 15.1917 24.9792 14.753 25.2375 14.4673C26.1409 13.59 26.2707 12.375 25.4699 11.4976Z" fill="url(#paint7_linear_429_676)"/>
                            <defs>
                                <linearGradient id="paint0_linear_429_676" x1="1.72406" y1="21.699" x2="12.8134" y2="15.3052" gradientUnits="userSpaceOnUse"><stop stop-color="#1FADC3"/><stop offset="0.7072" stop-color="#36B98F"/></linearGradient>
                                <linearGradient id="paint1_linear_429_676" x1="8.56835" y1="15.7463" x2="9.70895" y2="14.7936" gradientUnits="userSpaceOnUse"><stop stop-color="#123F06" stop-opacity="0"/><stop offset="1" stop-color="#123F06"/></linearGradient>
                                <radialGradient id="paint2_radial_429_676" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.57513 15.5966) scale(6.29702 6.29532)"><stop offset="0.4185" stop-color="#123F06" stop-opacity="0"/><stop offset="1" stop-color="#123F06"/></radialGradient>
                                <linearGradient id="paint3_linear_429_676" x1="2.22148" y1="4.5117" x2="12.2161" y2="10.6221" gradientUnits="userSpaceOnUse"><stop stop-color="#9FDB57"/><stop offset="1" stop-color="#71CA5E"/></linearGradient>
                                <linearGradient id="paint4_linear_429_676" x1="13.6466" y1="21.7056" x2="24.7358" y2="15.3121" gradientUnits="userSpaceOnUse"><stop stop-color="#1FADC3"/><stop offset="0.7072" stop-color="#36B98F"/></linearGradient>
                                <linearGradient id="paint5_linear_429_676" x1="20.6034" y1="15.6437" x2="21.744" y2="14.6907" gradientUnits="userSpaceOnUse"><stop stop-color="#123F06" stop-opacity="0"/><stop offset="1" stop-color="#123F06"/></linearGradient>
                                <radialGradient id="paint6_radial_429_676" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18.4918 15.5966) scale(6.29702 6.29532)"><stop offset="0.4185" stop-color="#123F06" stop-opacity="0"/><stop offset="1" stop-color="#123F06"/></radialGradient>
                                <linearGradient id="paint7_linear_429_676" x1="14.1318" y1="4.5167" x2="24.1269" y2="10.6269" gradientUnits="userSpaceOnUse"><stop stop-color="#9FDB57"/><stop offset="1" stop-color="#71CA5E"/></linearGradient>
                            </defs>
                        </svg>
                        </span>
                            Рахунок на оплату LiqPay
                        </button>
                        <el-row>
                            <el-col>
                                <modal-replenishment/>
                            </el-col>
                        </el-row>

                        <button @click="showModalGetInvoiceLegal()" class="btn btn-warning payments-ways-btn" type="button">
                            Згенерувати рахунок-фактуру
                        </button>
                        <create-invoice ref="createInvoice" :hide-button="true"></create-invoice>
                        <hr class="hr-grey">
                        <div>
                            <div class="text-center">
                                <b>Або за реквізитами рахунку ФОП в українській гривні:</b>
                            </div>
                            <br>
                            <div>
                                Отримувач: ТОВ ЮІНШУР<br>
                                Код отримувача: 45581136<br>
                                Рахунок IBAN: UA77 305299 00000 26003006118349<br>
                                Назва банку: АТ КБ "ПРИВАТБАНК"<br>
                                <div class="warning">
                                    Призначення платежу:<br>
                                    За оформлення страхових полісів, ##{{$store.getters.USER.id}}
                                </div>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <button class="btn-white" type="button" @click="close()">Скасування</button>
                        <div v-if="this.successMessage" class="stepThree">
                            <span class="successMessage">Посилання на оплату успішно скопійоване!</span>
                            <button class="btn-blue" type="button" @click="close()">Закрити</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import ModalReplenishment from "@/components/app/modals/modal-replenishment.vue";
import CreateInvoice from "@/components/app/invoice/CreateInvoice.vue";

export default {
    components: {CreateInvoice, ModalReplenishment, VueSlider},
    name: 'ModalGetAllPaymentWays',
    props: ['isLegalType'],
    data() {
        return {
            paySum: 0,
            paymentLink: null,
            orderId: null,
            orderData: null,
            successMessage: false,
            preloader: false,
            goToHref: '',
            legalType: false,
            money: {
                decimal: ',',
                thousands: ' ',
                prefix: '',
                precision: 0,
                masked: false /* doesn't work with directive */
            },
            dialogVisible: false
        };
    },
    created() {
        this.legalType = this.isLegalType;

    },
    methods: {
        close() {
            this.dialogVisible = false;
            this.$emit('close')
        },
        open: function (orderId, price, orderData) {
            this.successMessage = false;
            this.orderId = orderId;
            this.orderData = orderData;

            if (this.orderData) {
                this.legalType = (this.orderData.insurant?.customerTypeId == 2)
            }

            this.paySum = price;
            this.dialogVisible = true;
        },
        copyLink(){
            this.successMessage = true;
            this.$clipboard(this.paymentLink);
        },
        showReplenishment: function(){
            this.$root.$emit('showReplenishment')
        },
        showModalGetInvoiceOrder() {
            this.$root.$emit('showReplenishment')
            // this.$emit('showModalGetInvoiceOrder', this.orderData);
            // this.close();
        },
        showModalGetInstantLoan() {
            this.$emit('showModalGetInstantLoan', this.orderData);
            this.close();
        },
        showModalGetMonoPayParts() {
            this.$emit('showModalGetMonoPayParts', this.orderData);
            this.close();
        },
        showModalGetInvoiceLegal() {
            this.$refs.createInvoice.dialogVisible = true;
            // this.$emit('showModalGetInvoiceLegal', this.orderData);
            // this.close();
        }
    }
};
</script>

<style>
.el-tooltip__popper {
    width: 30%;
}
</style>
<style lang="scss" scoped>

.modal-mask {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.custom-modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.custom-modal-container {
    max-width: 480px !important;
    @media (max-width: 600px) {
        max-width: 400px!important;
        //margin:0;
    }
    .hr-grey {
        width: 400px;
        //height: 0px;
        //border: 0.5px solid #DDE2E5;
    }
    .policy-link {
        width: 436px;
        height: 64px;
        background: #F6F7FB;
        border-radius: 5px;
        display: flex;
        @media (max-width: 992px) {
            width: 300px;
        }

        .link {
            margin: auto 0 auto 12px;
            white-space: nowrap;
            overflow: hidden;
            max-width: 67%;
            padding: 5px;
            text-overflow: ellipsis;

            @media (max-width: 992px) {
                margin: auto 0 auto 2px;
            }
        }
    }
    .message {
        width: 258px; height: 29px;font-family: Circe,serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 29px;
        text-align: center;
        color: #24282C;

        @media (max-width: 992px) {
            width: 258px;
            height: 21px;
            font-size: 17px;
            line-height: 20px;
        }
    }

    .mtsbu-icon {
        margin: auto 0 auto 20px;

        @media (max-width: 992px) {
            margin: auto 0 auto 5px;
        }
    }

    .payments-ways-btn {
        width: 100%;
        max-width: 400px;
        background-color: #F79E1B;
        border-radius: 3px;
        font-weight: 700;
        font-size: 18px;
        color: #fff;
        padding: 10px 15px;
        margin-top: 10px;
        margin-bottom: 10px;
        .payments-icon {
            float: left;
        }
        @media (max-width: 600px) {
            font-size: 16px;
        }
    }
    .payments-ways-btn.disable{
        background-color: #c0c0c0;
        border-color: #c0c0c0;
    }
    .payments-ways-btn .label {
        position: absolute;
        right: 1px;
        top: 1px;
        z-index: 2;
        color: #EF4137;
        text-transform: uppercase;
        text-align: center;
        font-size: 10px;
        line-height: normal;
        font-weight: bold;
        height: 15px;
        padding: 0 4px;
        border: 1px solid #EF4137;
        border-radius: 3px;
    }

    @media (max-width: 992px) {
        width:358px;
    }
    width: 516px;
    height: 217px;
    background: #FFFFFF;
    border-radius: 5px;
    margin: 0 auto;
    padding: 20px 30px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.custom-modal-custom-header h3 {
    margin-top: 0;
    color: #42b983;
}

.custom-modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
}

.warning {
    color:red;
}
.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .custom-modal-container,
.modal-leave-active .custom-modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}


</style>
