import {checkOtp, checkOtpSubmitted, sendOtp} from "@/api/order";

export default {
    methods: {
        formSendOtp(){
            this.$refs.ChoiceOtpSendingDirection.open();
        },
        otpChanged(){
            this.otpFlags.sendingOtpError = false;
            this.otpFlags.submitOtpCheck = false;
            this.otpFlags.otpCodeValidate = true;
        },
        async otpCheck(otpPass){

            this.otpFlags.submitOtpCheck = true;
            if(!otpPass) return;
            this.otpFlags.pandingOtpCheck = true;
            let resCheckOtp = await checkOtp(this.orderId, otpPass).catch(error =>  {});
            const resCheckOtpStatus = resCheckOtp?.data?.message ?? null;
            if(resCheckOtpStatus !== "OK"){
                this.otpFlags.otpCodeValidate = false;
            }else{
                this.otpFlags.otpCodeValidate = true;
                this.otpFlags.confirmOtpStatus = true;
                this.otpFlags.otpPass = otpPass;

                // if(this.productType && this.productType == 'tourism'){
                //     this.confirmTourismOrder();
                // }
            }
            this.otpFlags.pandingOtpCheck = false;
        },
        async sendOtpApi(type){
            this.otpFlags.sentOtpPass = true;
            this.otpPass = "";
            this.otpFlags.pandingOtpSending = true;
            let sendOtpRes = await sendOtp(this.orderId, type);
            const sendOtpStatus = sendOtpRes?.data?.message ?? null;
            if(sendOtpStatus !== "OK"){
                this.otpFlags.sendingOtpError = true;
            }else{
                this.otpFlags.sendingOtpError = false;
            }
            this.otpFlags.pandingOtpSending = false;
        },
        checkOtpEntered() {
            if (this.order.otpHistory?.date_otp_entered) {
                this.agreement = true;
                this.otpFlags.confirmOtpStatus = true;
                this.otpFlags.sentOtpPass = true;
                this.$refs.otpBlock.$data.otpPass = this.order.otpHistory.code;
                let freshData = false;
                if(!this.confirmContractStatus){
                    if(this.productType && this.productType == 'tourism'){
                        freshData = false;
                    }else{
                        freshData = true;
                    }
                    if(freshData){
                        this.otpFlags = {
                            otpPass: "", // ОТР - код
                            otpStatus: true, // true - Включить отправку ОТР - кода
                            confirmOtpStatus: false, // true - OTP код проверен успешно
                            sentOtpPass: false, // true - OTP код был отправлен mail/viber/sms
                            sendingOtpError: false, // true - при отправке ОТР кода произошла ошибка
                            submitOtpCheck: false, // true - была нажата кнопка отправки ОТР кода на проверку
                            otpCodeValidate: true, // статус проверки ОТР кода true - прошел проверку, false - код неверный
                            pandingOtpSending: false, // флаг для отображения прелоадера при отправке ОТР кода
                            pandingOtpCheck: false, // флаг для отображения прелоадера при отправке ОТР кода на проверку
                        };
                    }
                }
            }
        },
    }
}
