import store from "@/store";

export default [
    {
        path: '/cancellation-requests',
        name: 'cancellation',
        meta: {
            layout: 'profileLayout',
            requiresAuth: true,
            title: 'Запити на ануляцію полісів'
        },
        // beforeEnter: (to, from, next) => {
        //     if (store.getters.USER_INFO.isActivePenalty) {
        //         next();
        //     } else {
        //         next({name: 'prime'})
        //     }
        // },
        component: () => import('../views/cancellation/index.vue')
    },
]

