import user from "@/store/modules/authorization_store";

const state = {
        searchTourismParams: null,
        infoOffer: null,
        fullOrderInfo: null,
        infoUser: null,
        touristsListInfo: null,
        contractId: null,
    };
    const getters = {
        GET_SEARH_TOURISM_PARAMS: state => state.searchTourismParams,
        GET_TOURISM_INFO_OFFER: state => state.infoOffer,
        GET_TOURISM_INFO_USER: state => state.infoUser,
        GET_TOURISM_INFO_TOURISTSLIST: state => state.touristsListInfo,
        GET_TOURISM_FULL_ORDER: state => state.fullOrderInfo,
        GET_TOURISM_CONTRACT_ID: state => state.contractId,
    };
    const mutations = {
        SET_SEARCH_TOURISM_PARAMS: (state, data) => {
            state.searchTourismParams = data;
        },
        SET_TOURISM_INFO_OFFER: (state, data) => {
            state.infoOffer = data;
            localStorage.setItem(user.state.keyUser + '_tourism_infoOrder', JSON.stringify(data));
        },
        SET_TOURISM_FULL_ORDER: (state,orderId) => {
            state.fullOrderInfo = {...state.searchTourismParams,  ...state.infoOffer, ...{info:state.infoUser}, ...{touristsListInfo:state.touristsListInfo}, orderId};
            localStorage.setItem(user.state.keyUser + '_tourism_fullInfoOrder', JSON.stringify(state.fullOrderInfo));
        },
        SET_TOURISM_CONTRACT_ID: (state, data) => {
            state.contractId = data;
        },
        CLEAR_TOURISM_FULL_ORDER: (state) => {
            state.fullOrderInfo = null;
            localStorage.setItem(user.state.keyUser + '_tourism_infoOrder', null);
            localStorage.setItem(user.state.keyUser + '_tourism_fullInfoOrder', null);
        },
        CLEAR_TOURISM_CONTRACT_ID: (state) => {
            state.contractId = null;
        },
        CLEAR_SEARCH_TOURISM_PARAMS: (state) => {
            state.searchTourismParams = null;
        },
        CLEAR_TOURISM_INFO_TOURISTSLIST: (state) => {
            state.touristsListInfo = null;
        },
        CLEAR_TOURISM_INFO_OFFER: (state) => {
            state.infoOffer = null;
        },
        CLEAR_TOURISM_INFO_USER: (state) => {
            state.infoUser = null;
        },
        SET_TOURISM_INFO_USER: (state, data) => {
            state.infoUser = data;
        },
        SET_TOURISM_INFO_TOURISTSLIST: (state, data) => {
            state.touristsListInfo = data;
        },
        INIT_TOURISM_FULL_INFO_ORDER: (state) => {
            const savedData = localStorage.getItem(user.state.keyUser + '_tourism_fullInfoOrder');
            if (savedData) {
                state.fullOrderInfo = JSON.parse(savedData);
            }
        },
        INIT_TOURISM_INFO_ORDER: (state) => {
            const savedData = localStorage.getItem(user.state.keyUser + '_tourism_infoOrder');
            if (savedData) {
                state.infoOffer = JSON.parse(savedData);
            }
        },
    };
    const actions = {
        initTourismFullInfoOrder({ commit }) {
            commit('INIT_TOURISM_FULL_INFO_ORDER'); // Загружаем данные из localStorage в state при старте
        },
        initTourismInfoOrder({ commit }) {
            commit('INIT_TOURISM_INFO_ORDER'); // Загружаем данные из localStorage в state при старте
        }
    };

    export default {
        state,
        getters,
        mutations,
        actions,
    };
