import franchises from '@/store/dictionaries/franchise_types'
import user from "@/store/modules/authorization_store";

export default {
    /**
     *
     * @returns {{osagoOffers: null}}
     */
    state: () => ({
        osagoOffers: null,
        loadingOsagoOffers: false,
        infoOrder: null,
        //TODO может быть стоит разделить информацию о данных согласно шагам.
        fullInfoOrder: null,
        order: null,
        searchParams: null,
        requestData: null,
        dateInfo: null
    }),

    getters: {
        OSAGO_OFFERS: state => state.osagoOffers,
        INFO_ORDER_OSAGO: state => state.infoOrder,
        FULL_INFO_ORDER: state => state.fullInfoOrder,
        GET_ORDER_OSAGO: state => state.order,
        GET_CAR_INFO: state => state.carInfo,
        GET_DATE_INFO: state => state.dateInfo,
    },

    mutations: {
        /**
         *
         * @param state
         * @param data
         * @param isConcat
         * @constructor
         */
        SET_OSAGO_OFFERS: (state, data) => {
            state.osagoOffers = data;
        },

        SET_LOADING_OSAGO_OFFERS: (state, data) => {
            state.loadingOsagoOffers = data
        },

        CONCAT_OSAGO_OFFERS: (state, data) => {
            if (state.osagoOffers === null) {
                state.osagoOffers = data;
            } else {
                state.osagoOffers = state.osagoOffers.concat(data)
            }
        },

        /**
         *
         * @param state
         * @param data
         * @constructor
         */
        SET_INFO_ORDER: (state, data) => {
            state.infoOrder = data;
            localStorage.setItem(user.state.keyUser + '_osago_infoOrder', JSON.stringify(data));
        },
        /**
         *
         * @param state
         * @param data
         * @constructor
         */
        SET_FULL_INFO_ORDER: (state, data) => {
            state.fullInfoOrder = data;
            localStorage.setItem(user.state.keyUser + '_osago_fullInfoOrder', JSON.stringify(data));
        },

        SET_SEARCH_PARAMS: (state, data) => {
            state.requestData = data;
        },

        SET_TEST_ORDER: (state, data) => {
            state.order = data;
        },

        SET_CAR_INFO: (state, data) => {
            state.carInfo = data;
        },

        SET_DATE_INFO: (state, data) => {
            state.dateInfo = data;
        },

        INIT_FULL_INFO_ORDER: (state) => {
            const savedData = localStorage.getItem(user.state.keyUser + '_osago_fullInfoOrder');
            if (savedData) {
                state.fullInfoOrder = JSON.parse(savedData);
            }
        },
        INIT_INFO_ORDER: (state) => {
            const savedData = localStorage.getItem(user.state.keyUser + '_osago_infoOrder');
            if (savedData) {
                state.infoOrder = JSON.parse(savedData);
            }
        }
    },

    actions: {
        /**
         *
         * @param commit
         * @param params
         * @returns {Promise<void>}
         * @constructor
         */
        GET_OSAGO_OFFERS({commit}, params) {
            commit('SET_LOADING_OSAGO_OFFERS', true)
            if (params.franchise == 'all') {
                commit('SET_OSAGO_OFFERS', null);
                let requests = [];

                let steps = 1

                const year = moment(params.startDate, "DD.MM.YYYY").year();
                let franchList = [];
                if(year >= 2025){
                    steps = 0;
                    franchList = franchises.state.types.filter(item => item.id === 0);
                }else{
                    franchList = franchises.state.types;
                }

                for (let key in franchList) {
                    if (franchList[key].id || franchList[key].id === 0) {
                        params.franchise = franchList[key].id;
                        requests[key] = Object.assign({}, params);

                        axios({
                            url: 'api/' + process.env.VUE_APP_SERVER_MODE + '/insurance/calculator/osago',
                            method: 'GET',
                            params: requests[key]
                        }).then(resp => {
                            resp.data.data = resp.data.data.map(offer => ({ ...offer, showModal: false }));
                            commit('CONCAT_OSAGO_OFFERS', resp.data.data);
                            if (steps === franchList.length - 1) {
                                commit('SET_LOADING_OSAGO_OFFERS', false)
                            }
                            steps++
                        }).catch(err => {
                            if (steps === franchList.length - 1) {
                                commit('SET_LOADING_OSAGO_OFFERS', false)
                            }
                            steps++
                            console.log(err);
                        });
                    }
                }
            } else {
                axios({
                    url: 'api/' + process.env.VUE_APP_SERVER_MODE + '/insurance/calculator/osago',
                    method: 'GET',
                    params: params
                })
                    .then(resp => {
                        resp.data.data = resp.data.data.map(offer => ({ ...offer, showModal: false }));
                        commit('SET_OSAGO_OFFERS', resp.data.data);
                        commit('SET_LOADING_OSAGO_OFFERS', false)
                    })
                    .catch(err => {
                        commit('SET_LOADING_OSAGO_OFFERS', false)
                        console.log(err);
                    });
            }
        },

        initOsagoFullInfoOrder({ commit }) {
            commit('INIT_FULL_INFO_ORDER'); // Загружаем данные из localStorage в state при старте
        },
        initOsagoInfoOrder({ commit }) {
            commit('INIT_INFO_ORDER'); // Загружаем данные из localStorage в state при старте
        }
    },
};
