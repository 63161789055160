import {uploadReceipt} from "@/api/invoice";

export default {
    data: function () {
        return {
            receiptLoaded: false,
        };
    },
    created: async function () {

    },
    computed: {
        orderStatusesMixin: function () {
            return this.$store.getters.ORDER_STATUSES;
        },
        concludeStatus: function () {
            return this.$_.findWhere(this.orderStatusesMixin, { type: 'concluded'})?.id;
        },
        draftStatus: function () {
            return this.$_.findWhere(this.orderStatusesMixin, { type: 'draft' })?.id;
        },
        declaredStatus: function () {
            return this.$_.findWhere(this.orderStatusesMixin, { type: 'declared'})?.id;
        },
        overdueStatus: function () {
            return this.$_.findWhere(this.orderStatusesMixin, { type: 'overdue'})?.id;
        },
        canceledStatus: function () {
            return this.$_.findWhere(this.orderStatusesMixin, { type: 'canceled'})?.id;
        },
        prolongationStatus: function () {
            //TODO ид просто взят для того,что бы хоть какой то был.
            // То есть это костыль. Скорее всего врменный, так как связан с тем,
            // что бы во фронт вставить без проблемно новый статус.
            return  { name: 'Пролонгація', id: 15, type: "prolongation"};
        },
        orderPaid() {
            if (this.order.payments && this.order.payments.length) {
                const sumPay = this.order.payments.reduce((sumPay, item) => {
                    if (item.invoice.status_id === 2) {
                        return sumPay + parseFloat(item.invoice.amount);
                    }
                    return sumPay;
                }, 0,);
                return sumPay >= this.order.allPrice;
            }

            return false;
        },
        canConfirmContract() {
            // this.confirmContractStatus &&
            return ((this.otpFlags.confirmOtpStatus && this.otpFlags.otpStatus) || !this.otpFlags.otpStatus) && (this.orderPaid || this.receiptLoaded)
        },
        showFileInputReceipt() {
            // this.confirmContractStatus &&
            return this.otpFlags.confirmOtpStatus && this.order.payments &&  this.order.payments.length && this.order.payments.some(item => item.invoice.type_id === 8)
        }
    },
    methods:{
        showStatusId: function(order) {
            return this.$_.findWhere(this.orderStatusesMixin, {id: parseInt(order.statusId)})?.id;
        },
        showStatusName: function(order) {
            return this.$_.findWhere(this.orderStatusesMixin, {id: parseInt(order.statusId)})?.name;
        },
        getStatusClass: function(order) {
            return 'status-' + this.$_.findWhere(this.orderStatusesMixin, {id: parseInt(order.statusId)})?.type;
        },
        checkOrderPaid(order) {
            if (order.payments.length) {
                const sumPay = order.payments.reduce((sumPay, item) => {
                    if (item.invoice.status_id === 2) {
                        return sumPay + parseFloat(item.invoice.amount);
                    }
                    return sumPay;
                }, 0,);

                return sumPay >= order.allPrice;
            }

            return false;
        },
        uploadReceipt(event) {
            const payment = this.order.payments.find(item => item.invoice.type_id === 8)

            if (payment) {
                uploadReceipt(payment.invoice.id, event.file).then(response => {
                    if (response.data.status === 'success') {
                        this.receiptLoaded = true;
                    }
                })
            }
        },
    }
}
