import { list as listCompanies } from '../../api/greenCardInsuranceCompany'
import user from "@/store/modules/authorization_store";

const getDefaultState = () => {
    return {
        greenCardCompanies: null,
        requestData: null,
        infoOffer: null,
        infoUser: null,
        fullOrderInfo: null,
        contractId: null,
        infoCar: null,
    }
}

const state = getDefaultState()

const actions = {
    async GET_GREENCARD_COMPANIES({ commit }, country, carType, userType, startDate, periodOption) {
        await listCompanies(country, carType, userType, startDate, periodOption)
            .then(resp => {
                commit('SET_GREENCARD_COMPANIES', resp?.data?.data ?? [])
            })
            .catch(err => {
                console.log(err)
            })
    },
    initGreenCardFullInfoOrder({ commit }) {
        commit('INIT_GREEN_CARD_FULL_INFO_ORDER'); // Загружаем данные из localStorage в state при старте
    },
    initGreenCardInfoOrder({ commit }) {
        commit('INIT_GREEN_CARD_INFO_ORDER'); // Загружаем данные из localStorage в state при старте
    }
}

const getters = {
    GREENCARD_COMPANIES: state => state.greenCardCompanies,
    GREENCARD_SEARCH_PARAMS: state => state.requestData,
    GREENCARD_INFO_OFFER: state => state.infoOffer,
    GREENCARD_INFO_USER: state => state.infoUser,
    GREENCARD_FULL_ORDER: state => state.fullOrderInfo,
    GREENCARD_CONTRACT_ID: state => state.contractId,
    GREENCARD_INFO_CAR: state => state.infoCar,
}

const mutations = {
    SET_GREENCARD_COMPANIES: (state, data) => {
      state.greenCardCompanies = data;
    },
    SET_GREENCARD_SEARCH_PARAMS: (state, data) => {
        state.requestData = data;
    },
    SET_GREENCARD_INFO_OFFER: (state, data) => {
        state.infoOffer = data;
        localStorage.setItem(user.state.keyUser + '_green_card_infoOrder', JSON.stringify(data));
    },
    SET_GREENCARD_INFO_USER: (state, data) => {
        state.infoUser = data;
    },
    SET_GREENCARD_INFO_CAR: (state, data) => {
        state.infoCar = data;
    },
    CLEAR_GREENCARD_INFO_CAR: (state) => {
        state.infoCar = null;
    },
    SET_GREENCARD_CONTRACT_ID: (state, data) => {
        state.contractId = data;
    },
    SET_GREENCARD_FULL_ORDER: (state,orderId) => {
        state.fullOrderInfo = {...state.requestData,  ...state.infoOffer, ...{info:state.infoUser}, orderId};
        localStorage.setItem(user.state.keyUser + '_green_card_full_order_info', JSON.stringify(state.fullOrderInfo));
    },
    CLEAR_GREENCARD_FULL_ORDER: (state) => {
        state.fullOrderInfo = null;
        localStorage.setItem(user.state.keyUser + '_green_card_infoOrder', null);
        localStorage.setItem(user.state.keyUser + '_green_card_full_order_info', null);
    },
    SET_GREENCARD_CHANGE_ORDER: (state,data) => {
        state.fullOrderInfo = data
    },
    INIT_GREEN_CARD_FULL_INFO_ORDER: (state) => {
        const savedData = localStorage.getItem(user.state.keyUser + '_green_card_full_order_info');
        if (savedData) {
            state.fullOrderInfo = JSON.parse(savedData);
        }
    },
    INIT_GREEN_CARD_INFO_ORDER: (state) => {
        const savedData = localStorage.getItem(user.state.keyUser + '_green_card_infoOrder');
        if (savedData) {
            state.infoOrder = JSON.parse(savedData);
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
};
