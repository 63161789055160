export function generate(data) {
    return axios({ url: '/api/invoice/generate',
        method: 'post',
        data: data,
        responseType: 'blob'
    });
}

export function uploadReceipt(invoiceId, file) {
    let form_data = new FormData();
    form_data.append('file', file);

    return axios.post(`/api/invoice/upload-receipt/${invoiceId}`, form_data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

export function checkPayment(invoiceId) {
    return axios({
        url: `/api/payments/check-invoice`,
        method: 'get',
        params: {invoice: invoiceId}
    });
}
