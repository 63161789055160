import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import penalties from './penalties'
import cancellation from "@/router/cancellation";


Vue.use(VueRouter)

const routes = [
    {
        path: '/prime',
        name: 'prime',
        meta: {layout: 'profileLayout', requiresAuth: true, title: 'Головна'},
        component: () => import('../views/prime/index')
    },
    {
        path: '/',
        redirect: '/prime',
        meta: {layout: 'profileLayout', requiresAuth: true},
        //component: () => import('../views/profile.vue'),
    },
    {
        path: '*',
        redirect: '/prime',
        meta: {layout: 'profileLayout', requiresAuth: true},
    },
    {
        path: '/news',
        name: 'news',
        meta: {layout: 'profileLayout', requiresAuth: true, title: 'Новини'},
        component: () => import('../views/news/list.vue'),
    },
    {
        path: '/news/:slug',
        name: 'newShow',
        meta: {layout: 'profileLayout', requiresAuth: true, title: 'Новини'},
        component: () => import('../views/news/show.vue'),
    },
    {
        path: '/knowledge-base',
        name: 'knowledgeBase',
        meta: {layout: 'profileLayout', requiresAuth: true, title: 'База знань'},
        component: () => import('../views/knowledge-base/index.vue'),
    },
    {
        path: '/knowledge-base-articles/:id',
        name: 'knowledgeBaseShow',
        meta: {layout: 'profileLayout', requiresAuth: true, title: 'База знаннь'},
        component: () => import('../views/knowledge-base/show.vue'),
    },
    {
        path: '/knowledge-base-categories',
        name: 'knowledgeBaseCategories',
        meta: {layout: 'profileLayout', requiresAuth: true, title: 'База знаннь'},
        component: () => import('../views/knowledge-base/categories.vue'),
    },
    {
        path: '/knowledge-base-categories/:id',
        name: 'knowledgeBaseCategoryShow',
        meta: {layout: 'profileLayout', requiresAuth: true, title: 'База знаннь'},
        component: () => import('../views/knowledge-base/showCategory.vue'),
    },
    {
        path: '/registration',
        name: 'registration',
        meta: {layout: 'loginLayout', disableIfLoggedIn: true},
        component: () => import('../views/registration.vue')
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        meta: {
            layout: 'loginLayout',
            disableIfLoggedIn: true
        },
        component: () => import('../views/auth/forgotPassword.vue')
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            layout: 'loginLayout',
            disableIfLoggedIn: true
        },
        component: () => import('../views/login.vue')
    },
    {
        path: '/promo',
        name: 'promo',
        meta: {
            layout: 'loginLayout',
            disableIfLoggedIn: true
        },
        component: () => import('../views/promo.vue')
    },
    {
        path: '/profile',
        name: 'profile',
        meta: {layout: 'profileLayout', requiresAuth: true, title: 'Профіль'},
        component: () => import('../views/profile.vue')
    },
    {
        path: '/history',
        name: 'history',
        meta: {layout: 'profileLayout', requiresAuth: true, title: 'Історія замовлень'},
        component: () => import('../views/history/index')
    },
    {
        path: '/clients',
        name: 'clients',
        meta: {layout: 'profileLayout', requiresAuth: true, title: 'Клієнти'},
        component: () => import('../views/clients/index')
    },
    {
        path: '/clients/:clientId',
        name: 'client_info',
        meta: {layout: 'profileLayout', requiresAuth: true, title: 'Інформація про клієнта'},
        component: () => import('../views/clients/clientFullInfo')
    },
    {
        path: '/referral',
        name: 'referrals',
        meta: {layout: 'profileLayout', requiresAuth: true, title: 'Реферальна програма'},
        component: () => import('../views/referral/index')
    },
    {
        path: '/propositions',
        name: 'propositions',
        meta: {layout: 'profileLayout', requiresAuth: true, title: 'Сторінки пропозицій'},
        component: () => import('../views/propositions/index')
    },
    {
        path: '/history/:orderId/:typeId/insurance-osago-info',
        name: 'history_insuranceOsagoInfo',
        meta: {layout: 'profileLayout', requiresAuth: true, title: 'Інформація про замовлення'},
        component: () => import('../views/insuranceOsagoInfo/index')
    },
    {
        path: '/history/:orderId/insurance-green-card-info',
        name: 'history_insuranceGreenCardInfo',
        meta: {layout: 'profileLayout', requiresAuth: true, title: 'Інформація про замовлення'},
        component: () => import('../views/insuranceGreenCardInfo/index')
    },
    {
        path: '/history/:orderId/insurance-tourism-info',
        name: 'history_insuranceTourismInfo',
        meta: {layout: 'profileLayout', requiresAuth: true, title: 'Інформація про замовлення'},
        component: () => import('../views/insuranceTourismInfo/index')
    },
    {
        path: '/prolongation',
        name: 'prolongation',
        meta: {layout: 'profileLayout', requiresAuth: true, title: 'Пролонгації'},
        component: () => import('../views/prolongation/index')
    },
    {
        path: '/password',
        name: 'password',
        meta: {layout: 'profileLayout', requiresAuth: true, title: 'Паролі'},
        component: () => import('../views/password.vue')
    },
    {
        path: '/bonus',
        name: 'bonus',
        meta: {layout: 'profileLayout', requiresAuth: true, title: 'Партнерська програма'},
        component: () => import('../views/bonus.vue')
    },
    {
        path: '/score',
        name: 'score',
        meta: {layout: 'profileLayout', requiresAuth: true, title: 'Мої рахунки'},
        component: () => import('../views/score/list.vue')
    },
    {
        path: '/products',
        name: 'products',
        meta: {layout: 'profileLayout', requiresAuth: true, title: 'Страхові продукти'},
        component: () => import('../views/insuranceProducts/index.vue')
    },
    {
        path: '/products/tariff/add',
        name: 'addTariff',
        meta: {layout: 'profileLayout', requiresAuth: true, title: 'Додати тариф'},
        component: () => import('../views/insuranceProducts/addTariff')
  },
  {
    path: '/partners',
    name: 'partners',
    meta: { layout: 'profileLayout', requiresAuth: true, title: 'Партнери' },
    component: () => import('../views/users.vue')
  },
  {
    path: '/order-step',
    name: 'orderSteps',
    meta: { layout: 'mainLayout', requiresAuth: true },
    component: () => import('../views/orderSteps.vue')
  },
  {
    path: '/search',
    name: 'search',
    meta: { layout: 'mainLayout', requiresAuth: true },
    component: () => import('../views/calculatorOsago/search.vue')
  },
  {
    path: '/order-final',
    name: 'orderFinal',
    meta: { layout: 'mainLayout', requiresAuth: true },
    component: () => import('../views/orderFinal.vue')
  },
  {
    path: '/osago-list',
    name: 'osagoResults',
    meta: { layout: 'mainLayout', requiresAuth: true },
    component: () => import('../views/osagoResults.vue')
  },
  {
    path: '/auth/password/reset/:email/:token',
    name: 'resetPassword',

        meta: {
            layout: 'loginLayout',
            disableIfLoggedIn: true
        },
        component: () => import('../views/auth/resetPassword.vue')
    },
    {
        path: '/bus-search',
        name: 'busSearch',
        meta: {layout: 'mainLayout', requiresAuth: true, requiresBus: true},
        component: () => import('../views/flight/search.vue')
    },
    {
        path: '/bus-list',
        name: 'busResults',
        props: true,
        meta: {layout: 'mainLayout', requiresAuth: true, requiresBus: true},
        component: () => import('../views/flight/results.vue')
    },
    {
        path: '/bus-steps',
        name: 'busSteps',
        meta: {layout: 'mainLayout', requiresAuth: true, requiresBus: true},
        component: () => import('../views/flight/steps.vue')
    },
    {
        path: '/bus-finnaly',
        name: 'busFinnaly',
        meta: {layout: 'mainLayout', requiresAuth: true, requiresBus: true},
        component: () => import('../views/flight/finnaly.vue')
    },
    {
        path: '/greencard-search',
        name: 'greenCard',
        meta: {layout: 'mainLayout', requiresAuth: true},
        component: () => import('../views/greencard/search.vue')
    },
    {
        path: '/greencard-list',
        name: 'greenCardResults',
        meta: {layout: 'mainLayout', requiresAuth: true},
        component: () => import('../views/greencard/results.vue')
    },
    {
        path: '/greencard-steps',
        name: 'greenCardSteps',
        meta: {layout: 'mainLayout', requiresAuth: true},
        component: () => import('../views/greencard/steps.vue')
    },
    {
        path: '/greencard-finnaly',
        name: 'greenCardFinnaly',
        meta: {layout: 'mainLayout', requiresAuth: true},
        component: () => import('../views/greencard/finnaly.vue')
    },
    {
        path: '/tourism-search',
        name: 'tourism-search',
        meta: {layout: 'mainLayout', requiresAuth: true},
        component: () => import('../views/tourism/search.vue')
    },
    {
        path: '/tourism-result',
        name: 'tourismResult',
        meta: {layout: 'mainLayout', requiresAuth: true},
        component: () => import('../views/tourism/results.vue')
    },
    {
        path: '/tourism-steps',
        name: 'tourismSteps',
        meta: {layout: 'mainLayout', requiresAuth: true},
        component: () => import('../views/tourism/steps.vue')
    },
    {
        path: '/tourism-finnaly',
        name: 'tourismFinnaly',
        meta: {layout: 'mainLayout', requiresAuth: true},
        component: () => import('../views/tourism/finnaly.vue')
    },
    {
        path: '/accident-lawyer-form',
        name: 'accidentLawyerForm',
        meta: {layout: 'mainLayout', requiresAuth: true},
        component: () => import('../views/accidentLawyer/index.vue')
    },
    {
        path: '/bonus-system',
        name: 'bonusSystem',
        meta: {
            layout: 'profileLayout',
            requiresAuth: true,
            requiresLevel: true,
            title: 'Бонусна система'
        },
        component: () => import('../views/bonusSystem/index.vue')
    },
    {
        path: '/links/:id',
        name: 'links',
        meta: {
            layout: 'customLayout',
            requiresAuth: false,
            requiresLevel: false,
            title: 'Посилання'
        },
        component: () => import('../views/links/index.vue')
    },
    ...penalties,
    ...cancellation,
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.query.utm_source == 'referral') {
        const utm_source = to.query.utm_source;
        const utm_medium = to.query.utm_medium;
        let utmParams = {
            utm_medium: utm_medium
        }
        utmParams = JSON.stringify(utmParams);
        const expiration = new Date();
        expiration.setDate(expiration.getDate() + 30); // Кукисы будут действовать 30 дней
        document.cookie = 'utm_source=' + escape(utm_source) + ';expires=' + expiration.toUTCString() + ';path=/';
        document.cookie = 'utm_medium=' + escape(utm_medium) + ';expires=' + expiration.toUTCString() + ';path=/';
        document.cookie = 'utm_params=' + escape(utmParams) + ';expires=' + expiration.toUTCString() + ';path=/';
    }


    if (to.meta.requiresAuth) {
        if (store.getters.IS_LOGGED_IN) {
            if (to.meta.requiresBus && store.getters.USER_INFO.busInsurance === false) {
                next({name: 'prime'});
            } else if (to.meta.requiresLevel && store.getters.USER_INFO.levelId === null) {
                next({name: 'prime'});
            } else {
                next();
            }
            return
        }

        next({name: 'promo'});
    } else if (to.meta.disableIfLoggedIn && store.getters.IS_LOGGED_IN) {
        next({name: 'profile'});
    } else if (to.meta.requiresAuth === false) {
        next();
    } else {
        next();
    }

})
export default router
